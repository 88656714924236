import React, { useState, useContext, useEffect } from "react";
import { Dropdown, Nav, Tab, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { post, get } from "../../../Services/ApiHelper";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { toast } from "react-toastify";
import { EDIT_RFQ_QUOTEITEM } from "../../../utls/constants";
import Astericks from "../../Common/Asterick";

const EditLineItemQuotesModal = (props) => {
  const [selectedPartPart, setselectedPartPart] = useState(
    props.data?.description
  );
  const [description, setDescription] = useState(props.data?.description);
  const [selectedPartCategory, setselectedPartCategory] = useState(
    props.data?.itemTypeName
  );

  const [itemSource, setItemSource] = useState(props.data?.quoteItemSourceName);
  const [quantity, setQuantity] = useState(props.data?.quantity);
  const [unitCost, setUnitCost] = useState(props.data?.unitCost);
  const token = localStorage.getItem("bearerToken");
  const { diagnosisSummary, setDiagnosisSummary } = useContext(
    DiagnosisDetailsContext
  );
  const [maxQuantity, setMaxQuantity] = useState(props.maxQuantity);
  //search inputs
  const [partPartSearch, setPartPartSearch] = useState("");
  const [partCategorySearch, setPartCategorySearch] = useState("");
  const [quantError, setQuantError] = useState(null);
  const [unitCostError, setUnitCostError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //const [activeKey, setActiveKey] = useState('first'); // Initial active key state

  useEffect(() => {
    setselectedPartPart(props.data?.description);
    setDescription(props.data?.description);
    setItemSource(props.data?.quoteItemSourceName);
    setselectedPartCategory(props.data?.itemTypName);
    setQuantity(props.data?.quantity);
    setUnitCost(props.data?.unitCost);
    setMaxQuantity(props.maxQuantity);
    setQuantError(null);
    setUnitCostError(null);
    setDescriptionError(null);
  }, [props.data, props.show]);

  const validateInputs = (activeTab) => {
    let isValid = true;
    setQuantError(null);
    setUnitCostError(null);
    setDescriptionError(null);
    if (activeTab === "second" && (!description || description == "")) {
      setDescriptionError("Required *");
      isValid = false;
    }
    if (!quantity || quantity == 0) {
      setQuantError("Required *");
      isValid = false;
    }
    if (props.data.quoteItemSourceName === "Parts" && quantity > maxQuantity) {
      setQuantError("Quantity exceeds projected *");
      isValid = false;
    }
    if (!unitCost || unitCost == 0) {
      setUnitCostError("Required *");
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = (activeTab) => {
    const isValid = validateInputs(activeTab);
    if (!isValid) {
      return;
    }
    setIsLoading(true);
    const payload = {
      quoteItemId: props.data.quoteItemId, //pass from page or use selectedtab or use props from passed page??
      quoteItemSource: activeTab === "first" ? "Parts" : "Other",
      itemDescription: activeTab === "first" ? selectedPartPart : description,
      quantity: quantity,
      unitCost: unitCost,
    };

    post(EDIT_RFQ_QUOTEITEM, payload, token)
      .then((response) => {
        props.onHide();
        setIsLoading(false);
        if (response.succeeded === true) {
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          const updateQuoteItemData = {
            description:
              props.data?.quoteItemSourceName === "Parts"
                ? selectedPartPart
                : description,
            quantity: quantity,
            unitCost: unitCost,
            totalCost: quantity * unitCost,
          };
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestQuoteSummary: {
              ...prevDiagnosisSummary?.requestQuoteSummary,
              quoteItems:
                prevDiagnosisSummary.requestQuoteSummary?.quoteItems.map(
                  (quoteitem) =>
                    quoteitem.quoteItemId === props.data.quoteItemId
                      ? {
                          ...quoteitem,
                          description:
                            props.data?.quoteItemSourceName === "Parts"
                              ? selectedPartPart
                              : description,
                          quantity: quantity,
                          unitCost: unitCost,
                          totalCost: quantity * unitCost,
                        }
                      : quoteitem
                ),
            },
          }));
          //Update Quote Total;remove previous and add new total
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestQuoteSummary: {
              ...prevDiagnosisSummary?.requestQuoteSummary,
              total:
                prevDiagnosisSummary?.requestQuoteSummary?.total -
                props.data.totalCost +
                quantity * unitCost,
            },
          }));
        } else {
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        props.onHide();
        setIsLoading(false);
        toast.error("An error occurred", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Line Item</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Enter information about the quote line item
            </span>
          </div>
          <div className="mt-4 pt-2 addLien-tab">
            <div className="fs-14 fw-semibold text-black">Cost Type</div>
            <Tab.Container
              defaultActiveKey={
                props.data?.quoteItemSourceName === "Parts" ? "first" : "second"
              }
              //activeKey={props.data.quoteItemSourceName === "Parts" ? "first" : "second"} // Pass activeKey state here
              //onSelect={(key) => setActiveKey(key)} // Update activeKey state when a tab is selected
            >
              <Nav variant="pills" className="py-3 mb-2">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    disabled={props.data?.quoteItemSourceName !== "Parts"}
                  >
                    Parts Costs
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    disabled={props.data?.quoteItemSourceName === "Parts"}
                  >
                    Other Costs
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="d-grid gap-4 modal-forms-content">
                    <div className="col-md-12">
                      <label className="fw-medium pb-2 fs-14">
                        Select Part
                      </label>
                      <Dropdown
                        className="select__form"
                        //onSelect={handlePartPartSelect}
                      >
                        <Dropdown.Toggle
                          className={`select-title ${
                            selectedPartPart !== "Select part" ? "selected" : ""
                          }`}
                          style={{ height: "50px" }}
                          disabled // Disable the toggle button
                        >
                          {selectedPartPart}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{ pointerEvents: "none" }} // Disable the dropdown menu
                        ></Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Part Quantity to be Costed
                          <Astericks />
                        </label>
                        {quantError && (
                          <p style={{ color: "red" }}>{quantError}</p>
                        )}
                      </div>
                      <input
                        defaultValue={props.data?.quantity}
                        className="modal-input-box"
                        type="number"
                        onChange={(e) => setQuantity(e.target.value)}
                        min="1"
                        max={maxQuantity}
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "50px",
                          borderRadius: "5px",
                          padding: "0 15px",
                        }}
                        placeholder="Enter quantity"
                        onKeyPress={(event) => {
                          // Prevent typing of negative sign
                          if (event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                        onInput={(event) => {
                          // Remove non-integer characters
                          event.target.value = event.target.value.replace(
                            /\D/g,
                            ""
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Enter Unit Cost
                          <Astericks />
                        </label>
                        {unitCostError && (
                          <p style={{ color: "red" }}>{unitCostError}</p>
                        )}
                      </div>
                      <input
                        defaultValue={props.data?.unitCost}
                        className="modal-input-box"
                        type="number"
                        onChange={(e) => setUnitCost(e.target.value)}
                        min="0"
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "50px",
                          borderRadius: "5px",
                          padding: "0 15px",
                        }}
                        placeholder="Enter unit part cost"
                        onKeyPress={(event) => {
                          // Prevent typing of negative sign
                          if (event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="d-grid gap-4 modal-forms-content">
                    <div className="col-md-12">
                      <label className="fw-medium pb-2 fs-14">
                        Cost Category
                      </label>
                      <Dropdown
                        className="select__form"
                        //onSelect={handlePartCategorySelect}
                      >
                        <Dropdown.Toggle
                          className={`select-title ${
                            selectedPartCategory !== "Select part"
                              ? "selected"
                              : ""
                          }`}
                          style={{ height: "50px" }}
                          disabled // Disable the toggle button
                          defaultValue={props.data?.itemTypeName}
                        >
                          {props.data?.itemTypeName}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{ pointerEvents: "none" }} // Disable the dropdown menu
                        ></Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Cost Description
                          <Astericks />
                        </label>
                        {descriptionError && (
                          <p style={{ color: "red" }}>{descriptionError}</p>
                        )}
                      </div>
                      <input
                        defaultValue={props?.data?.description}
                        className="modal-input-box"
                        type="text"
                        onChange={(e) => setDescription(e.target.value)}
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "50px",
                          borderRadius: "5px",
                          padding: "0 15px",
                        }}
                        placeholder="Enter cost description"
                      />
                    </div>
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Quantity
                          <Astericks />
                        </label>
                        {quantError && (
                          <p style={{ color: "red" }}>{quantError}</p>
                        )}
                      </div>
                      <input
                        defaultValue={props.data?.quantity}
                        className="modal-input-box"
                        type="number"
                        onChange={(e) => setQuantity(e.target.value)}
                        min="1"
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "50px",
                          borderRadius: "5px",
                          padding: "0 15px",
                        }}
                        placeholder="Enter quantity"
                        onKeyPress={(event) => {
                          // Prevent typing of negative sign
                          if (event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                        onInput={(event) => {
                          // Remove non-integer characters
                          event.target.value = event.target.value.replace(
                            /\D/g,
                            ""
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="fw-medium pb-2 fs-14">
                          Unit Cost
                          <Astericks />
                        </label>
                        {unitCostError && (
                          <p style={{ color: "red" }}>{unitCostError}</p>
                        )}
                      </div>
                      <input
                        defaultValue={props.data?.unitCost}
                        className="modal-input-box"
                        type="number"
                        onChange={(e) => setUnitCost(e.target.value)}
                        min="0"
                        style={{
                          background: "#F1EFEF",
                          width: "100%",
                          border: "0",
                          height: "50px",
                          borderRadius: "5px",
                          padding: "0 15px",
                        }}
                        placeholder="Enter unit cost"
                        onKeyPress={(event) => {
                          // Prevent typing of negative sign
                          if (event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link
                to=""
                className="delate-btn"
                onClick={() =>
                  onSubmit(
                    props.data.quoteItemSourceName === "Parts"
                      ? "first"
                      : "second"
                  )
                }
              >
                Save
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditLineItemQuotesModal;
