import React from "react";
import usefetchData from "../../../hooks/useFetchData";
import { useParams } from "react-router-dom";

const ViewWorkCostsSummary = () => {
  const { id } = useParams();
  const ticketId = Number(id);

  const { data, isLoading } = usefetchData(
    ["ticket-summary", ticketId],
    `/Tickets/GetTicketCostSummaryById/${ticketId}`,
    {},
    "Couldn't get ticket summary",
    true
  );

  const workDetails = data?.workDetails;


  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">WORK DETAILS</div>
        </div>
        <div className="order-request mt-4 p-0">
          <div className="request-box">
            <div className="request-box-title">
              <div className="fs-13 fw-medium">WORK INFORMATION</div>
            </div>
            <div className="request-box-body p-0">
              <div className="request_content_costs">
                <div className="request_content_costs_item">
                  <div className="fs-14 fw-semibold title-cost">
                    ASSET DETAILS
                  </div>

                  <div className="request_item">
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "rgba(0, 0, 0, 0.38)" }}
                    >
                      Location
                    </div>
                    <div
                      className="fs-14 fw-medium"
                      style={{
                        color: "rgba(88, 69, 57, 0.87)",
                      }}
                    >
                      {workDetails?.locationName}
                    </div>
                  </div>
                  <div className="request_item">
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "rgba(0, 0, 0, 0.38)" }}
                    >
                      Asset Category
                    </div>
                    <div
                      className="fs-14 fw-medium"
                      style={{
                        color:
                           "rgba(88, 69, 57, 0.87)",
                      }}
                    >
                      {workDetails?.categoryOfWorkName}
                    </div>
                  </div>
                  <div className="request_item">
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "rgba(0, 0, 0, 0.38)" }}
                    >
                      Assets
                    </div>
                    {workDetails?.assetNameList?.map((item) => (
                      <div
                        className="fs-14 fw-medium"
                        style={{
                          color:
                            "rgba(88, 69, 57, 0.87)",
                        }}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="request_content_costs_item">
                  <div className="fs-14 fw-semibold title-cost">
                    WORK ORDER DETAILS
                  </div>

                  <div className="request_item">
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "rgba(0, 0, 0, 0.38)" }}
                    >
                      Work Order Title
                    </div>
                    <div
                      className="fs-14 fw-medium"
                      style={{
                        color:
                          "rgba(88, 69, 57, 0.87)",
                      }}
                    >
                      {workDetails?.ticketTitle}
                    </div>
                  </div>
                  <div className="request_item">
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "rgba(0, 0, 0, 0.38)" }}
                    >
                      Category of Work
                    </div>
                    <div
                      className="fs-14 fw-medium"
                      style={{
                        color:
                          "rgba(88, 69, 57, 0.87)",
                      }}
                    >
                      {workDetails?.categoryOfWorkName}
                    </div>
                  </div>
                  <div className="request_item">
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "rgba(0, 0, 0, 0.38)" }}
                    >
                      Team
                    </div>
                    <div
                      className="fs-14 fw-medium"
                      style={{
                        color:
                          "rgba(88, 69, 57, 0.87)",
                      }}
                    >
                      {workDetails?.assignedTeamName}
                    </div>
                  </div>
                  <div className="request_item">
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "rgba(0, 0, 0, 0.38)" }}
                    >
                      Worker
                    </div>
                    <div
                      className="fs-14 fw-medium"
                      style={{
                        color:
                           "rgba(88, 69, 57, 0.87)",
                      }}
                    >
                      {workDetails?.assignedUserName}
                    </div>
                  </div>

                  <div className="request_item">
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "rgba(0, 0, 0, 0.38)" }}
                    >
                      Description
                    </div>
                    <div
                      className="fs-14 fw-medium"
                      style={{
                        color:
                           "rgba(88, 69, 57, 0.87)",
                      }}
                    >
                      {workDetails?.ticketDescription}
                    </div>
                  </div>
                  <div className="request_item">
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "rgba(0, 0, 0, 0.38)" }}
                    >
                      Priority
                    </div>
                    <div
                      className="fs-14 fw-medium"
                      style={{
                        color:
                          workDetails?.priorityName === "High"
                            ? "#D57D2A"
                            : "rgba(88, 69, 57, 0.87)",
                      }}
                    >
                      {workDetails?.priorityName}
                    </div>
                  </div>
                </div>

                <div className="request_item">
                  <div className="fs-14 fw-semibold title-cost">
                    OTHER INFORMATION
                  </div>
                  <div
                    className="fs-14 fw-medium pt-2"
                    style={{ color: "rgba(0, 0, 0, 0.38)" }}
                  >
                    Files:
                  </div>
                  <ul>
                    {workDetails?.ticketFileList?.map((file) => (
                      // <li>Something</li>
                      <li
                        key={file?.fileName}
                        className="fs-14 fw-medium"
                        style={{
                          color: "#D57D2A",
                          display: "block",
                          marginBottom: "6px",
                        }}
                      >
                        {file?.fileName}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const request = [
  {
    assetDetailsTitle: "ASSET DETAILS",
    assetDetails: [
      {
        title: "Location",
        desc: "Gigiri",
      },
      {
        title: "Asset Category",
        desc: "Pumps",
      },
      {
        title: "Assets",
        desc: "Pump1, Pump 2",
      },
    ],
  },
  {
    assetDetailsTitle: "WORK ORDER DETAILS",
    assetDetails: [
      {
        title: "Work Order Title:",
        desc: "Pump Maintenance",
      },
      {
        title: "Category of Work:",
        desc: "Engineering",
      },
      {
        title: "Team:",
        desc: "AZ Engineers",
      },
      {
        title: "Worker:",
        desc: "Not Assigned",
      },
      {
        title: "Description:",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
      },
      {
        title: "Priority:",
        desc: "High",
      },
    ],
  },
];

const attachedFiles = [
  "Pump1.jpg",
  "Pump1 Meter.mp4",
  "Calibration manual.pdf",
];

export default ViewWorkCostsSummary;
