import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { NavLink, useLocation, useParams } from "react-router-dom";
import usefetchData from "../../../hooks/useFetchData";

const ViewLocationSidebar = () => {
  const [eventKey, setEventKey] = React.useState("");

  //get url by using useLocation
  const location = useLocation();
  const { id } = useParams();

  // get location data by id
  const { data: locationSummary, isLoading: isLoadingCondition } = usefetchData(
    ["location-summary", parseInt(id)],
    `/Locations/GetLocationSummaryById/${id}`,
    {},
    "Error fetching location data by id"
  );

  useEffect(() => {
    if (location.pathname === `/locations/view-location/${id}`) {
      setEventKey("0");
    } else if (location.pathname === `/locations/view-location/${id}/assets`) {
      setEventKey("1");
    } else if (
      location.pathname === `/locations/view-location/${id}/stakeholders`
    ) {
      setEventKey("2");
    } else if (location.pathname === `/locations/view-location/${id}/work`) {
      setEventKey("3");
    }
  }, [location, id]);

  useEffect(() => {}, []);

  return (
    eventKey && (
      <>
        <div className="order-sidebar-content p-0">
          <div className="p-4">
            <div className="fs-13 fw-medium">LOCATION INFORMATION</div>
            <div className="washroom-sidebar mt-3 d-flex align-items-end">
              <div style={{ width: "100%" }}>
                <div
                  className="fs-16 fw-bold pb-2"
                  style={{ color: "#6C5B51" }}
                >
                  Location Name
                </div>
                <div className="fs-20 fw-bold" style={{ color: "#D57D2A" }}>
                  {locationSummary?.locationSummary?.locationName}
                </div>
              </div>
            </div>
          </div>

          <Accordion defaultActiveKey={eventKey} className="view-work-sidebar">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <NavLink to={`/locations/view-location/${id}`} end>
                  Summary
                </NavLink>
              </Accordion.Header>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <NavLink to={`/locations/view-location/${id}/assets`}>
                  Assets
                </NavLink>
              </Accordion.Header>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="2">
              <Accordion.Header>
                <NavLink to={`/locations/view-location/${id}/stakeholders`}>
                  Stakeholders
                </NavLink>
              </Accordion.Header>
            </Accordion.Item> */}
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <NavLink to={`/locations/view-location/${id}/work`}>
                  Work
                </NavLink>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
        </div>
      </>
    )
  );
};

export default ViewLocationSidebar;
