import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { get, post } from "../../../Services/ApiHelper";
import {
  EDIT_LABOUR_COST,
  GET_ALL_USERS_BY_TEAM,
  GET_TEAMS_TO_ASSIGN_TICKET,
} from "../../../utls/constants";

const SummaryEditLabourCostsModal = ({ show, onHide, data }) => {
  const [selectTeam, setSelectTeam] = useState("Select team");
  const [selectTeamId, setSelectTeamId] = useState(0);
  const [selectWorker, setSelectWorker] = useState("Select worker");
  const [selectWorkerId, setSelectWorkerId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [teams, setTeams] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [hours, setHours] = useState(0);
  const [ratePerHour, setRatePerHour] = useState(0);

  const token = localStorage.getItem("bearerToken");

  const { ticketSummary, setTicketSummary } = useContext(WorkOrderContext);

  // const ticketId = ticketSummary?.workOrderSummary?.ticketId

  //set edit data

  useEffect(() => {
    get(GET_TEAMS_TO_ASSIGN_TICKET, token)
      .then((response) => setTeams(response))
      .catch((error) => console.log(error));

    get(GET_ALL_USERS_BY_TEAM(selectTeamId), token)
      .then((response) => setWorkers(response, () => {}))
      .catch((error) => console.log(error));
  }, [selectTeamId]);

  //demo data
  const teamName = [
    { name: "Select  " },
    { name: "team" },
    { name: "A - Z Engineering	" },
  ];
  const workerName = [
    { name: "Select  " },
    { name: "worker  " },
    { name: "John Doe	" },
  ];
  //get search input
  const [selectTeamSearch, setSelectTeamSearch] = useState("");
  const [selectWorkerSearch, setSelectWorkerSearch] = useState("");

  //filter via search input
  const filterSelectTeamData = teams.filter((item) =>
    item.teamName.toLowerCase().includes(selectTeamSearch.toLowerCase())
  );
  const filterSelectWorkdersData = workers.filter((item) =>
    `${item?.user?.userFirstName} ${item?.user?.userLastName}`
      .toLowerCase()
      .includes(selectWorkerSearch.toLowerCase())
  );

  //handle select
  const handleSelectTeam = (eventKey) => {
    const teamObject = teams.find((t) => t.teamName === eventKey);

    if (teamObject) {
      setSelectTeam(eventKey);
      setSelectTeamId(teamObject.id);
      setSelectWorker("");
      setSelectWorkerId();
    }

    setSelectTeamSearch("");
  };

  const handleSelectWorker = (eventKey) => {
    const selectedWorker = workers?.find(
      (item) =>
        item?.user?.userFirstName + "" + item?.user?.userLastName === eventKey
    );

    if (selectedWorker) {
      setSelectWorker(
        selectedWorker?.user?.userFirstName +
          " " +
          selectedWorker?.user?.userLastName
      );
      setSelectWorkerId(selectedWorker?.userId);
    } else {
      setSelectWorker("Select worker");
      setSelectWorkerId(0);
    }

    setSelectWorkerSearch("");
  };

  const handleWorkHoursChange = (e) => {
    setHours(parseInt(e.target.value, 10));
  };

  const handleRatePerHourChange = (e) => {
    setRatePerHour(parseInt(e.target.value, 10));
  };

  const onSubmit = () => {
    setIsLoading(true);
    const payload = {
      id: data.id,
      teamId: selectTeamId === 0 || "0" ? data?.teamId : selectTeamId,
      userId: selectWorkerId === 0 || "0" ? data?.userId : selectWorkerId,
      ratePerHour: ratePerHour === 0 ? data?.perHourRate : ratePerHour,
      hoursWorked: hours === 0 ? data?.manHoursUsed : hours,
    };

    post(EDIT_LABOUR_COST, payload, token)
      .then((response) => {
        setIsLoading(false);

        if (response.succeeded === true) {
          setIsLoading(false);

          onHide();
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          const index =
            ticketSummary.workOrderSummary.ticketLabourList.findIndex(
              (item) => item.id === data.id
            );

          if (index !== -1) {
            setTicketSummary((prevTicketSummary) => {
              const updatedTicketSummary = { ...prevTicketSummary };
              updatedTicketSummary.workOrderSummary.ticketLabourList[index] = {
                ...data,
                manHoursUsed: hours === 0 ? data.manHoursUsed : hours,
                perHourRate: ratePerHour === 0 ? data.perHourRate : ratePerHour,
                userName:
                  selectWorker === "Select worker"
                    ? data.userName
                    : selectWorker,
                userId:
                  selectWorkerId === 0 || "0" ? data.userId : selectWorkerId,
                teamName:
                  selectTeam === "Select team" ? data.teamName : selectTeam,
                teamId: selectTeamId === 0 || "0" ? data.teamId : selectTeamId,
              };
              return updatedTicketSummary;
            });
          }
        } else {
          setIsLoading(false);
          onHide();
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Edit Labour Costs</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Select the team and worker to add labour costs incurred.
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <label className="fw-medium pb-2">Select Team</label>
              <Dropdown className="select__form" onSelect={handleSelectTeam}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectTeam !== "Select team" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectTeam !== "Select team" ? selectTeam : data.teamName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={selectTeamSearch}
                      onChange={(e) => setSelectTeamSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filterSelectTeamData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.teamName}>
                        {item.teamName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <label className="fw-medium pb-2">Select Worker</label>
              <Dropdown className="select__form" onSelect={handleSelectWorker}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectWorker !== "Select worker" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectWorker !== "Select worker"
                    ? selectWorker
                    : data.userName}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setSelectWorkerSearch(e.target.value)}
                      value={selectWorkerSearch}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filterSelectWorkdersData.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={
                          item.user?.userFirstName +
                          "" +
                          item.user?.userLastName
                        }
                      >
                        {item.user?.userFirstName +
                          " " +
                          item.user?.userLastName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <label className="fw-medium pb-2">Hours Worked </label>

              <input
                defaultValue={data?.manHoursUsed}
                className="modal-input-box"
                onChange={(e) => setRatePerHour(parseInt(e.target.value, 10))}
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Hours spent on the work"
              />
            </div>
            <div className="col-md-12">
              <label className="fw-medium pb-2">Rate per Hour (KES)</label>
              <input
                defaultValue={data?.perHourRate}
                onChange={(e) => setRatePerHour(parseInt(e.target.value, 10))}
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter rate per hour"
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={onHide}>
              Cancel
            </button>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={onSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SummaryEditLabourCostsModal;
