import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import usefetchData from "../../../hooks/useFetchData";
import useMutateData from "../../../hooks/useMutateData";
import { useParams } from "react-router-dom";
import { FaCircleCheck } from "react-icons/fa6";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";

const EditRequestModal = (props) => {
  const { id } = useParams();

  // Fetch data for the request details
  const { data, isLoading } = usefetchData(
    ["request-data", parseInt(id)],
    `Requests/RequestDetails/${parseInt(id)}`,
    {},
    "Couldn't get",
    true
  );

  const mainData = data?.data;


  // State for form fields
  const [locationId, setLocationId] = useState(0);
  const [assetId, setAssetId] = useState(0);
  const [faultIds, setFaultIds] = useState([]);
  const [faultDescription, setFaultDescription] = useState("");
  const [recurrence, setRecurrence] = useState(false);
  const [operationalStatusId, setOperationalStatusId] = useState(0);

  useEffect(() => {
    if (mainData) {
      setLocationId(mainData.location?.id || 0);
      setAssetId(mainData.asset?.id || 0);
      setFaultIds(mainData.requestFaults?.map((fault) => fault.id) || []);
      setFaultDescription(mainData.requestDetails || "");
      setRecurrence(mainData.recurrenceStatus || false);
      setOperationalStatusId(props.requestData?.requestSummaryDetailDto?.operationalStatus?.id || 0);
    }
  }, [mainData]);


  // State for form validation
  const [faultsError, setFaultsError] = useState(false);

  // Fetch data for dropdowns
  const { data: locationsData } = usefetchData(
    ["locations"],
    `Locations/LocationsList`,
    {},
    "Error fetching locations"
  );
  const { data: assetsData } = usefetchData(
    ["assets-by-location", locationId],
    `Assets/GetAssetsByLocation/${locationId}`,
    {},
    "Error fetching assets by location",
    !!locationId
  );
  const { data: faultsData } = usefetchData(
    ["faults-by-asset", assetId],
    `Assets/GetFaultsByAsset/${assetId}`,
    {},
    "Error fetching faults by asset",
    !!assetId
  );
  const { data: operationalStatusData } = usefetchData(
    ["operational-status-by-asset", assetId],
    `Tickets/GetAllOperationStatusByAssetId/${assetId}`,
    {},
    "Error fetching operational statuses by asset",
    !!assetId
  );

  // Mutate function to edit request
  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: "/Requests/EditRequestInfo",
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success("Request edited successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        props.onHide();
      } else {
        toast.error("Failed to edit request", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    },
    successMessage: "Request edited successfully",
    errorMessage: "Failed to edit request",
    queryKeysToInvalidate: [["request-data", parseInt(id)]],
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if faults are selected
    if (faultIds.length === 0) {
      setFaultsError(true);
      toast.error("Please select at least one fault");
      return;
    }

    const payload = {
      requestId: parseInt(id),
      locationId: parseInt(locationId),
      assetId: parseInt(assetId),
      faultIds: faultIds.map((faultId) => parseInt(faultId)),
      faultDescription,
      recurrence,
      operationalStatusId: parseInt(operationalStatusId),
    };
    console.log("payload edit", payload);
    mutate(payload);
  };

  const handleFaultChange = (faultId) => {
    // Toggle fault selection
    setFaultIds(
      (prevFaultIds) =>
        prevFaultIds.includes(faultId)
          ? prevFaultIds.filter((id) => id !== faultId) // Remove if already selected
          : [...prevFaultIds, faultId] // Add if not selected
    );
  };

  const maxDisplayItems = 3; // Number of items to show before truncating

const displayedText = faultIds.length > 0
  ? faultIds
      .map((id) => faultsData?.find((fault) => fault.id === id)?.faultName)
      .slice(0, maxDisplayItems)
      .join(", ") + (faultIds.length > maxDisplayItems ? ", ..." : "")
  : "Select Faults";


  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body
        style={{
          padding: "28px 30px",
          background: "transparent",
          overflow: "hidden",
        }}
      >
        <div className="delate-content">
          <div
            className="d-md-flex align-items-center gap-4"
            style={{ marginBottom: "45px" }}
          >
            <span className="fs-16 fw-bold">Edit Request</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Edit request information
            </div>
          </div>
          <div className="row row-gap mt-3">
            {/* Location Dropdown */}
            <div className="col-md-12" style={{ marginBottom: "15px" }}>
              <label className="fw-medium pb-2" style={{ marginBottom: "8px" }}>
                Location
              </label>
              <Dropdown
                className="select__form"
                onSelect={(e) => setLocationId(parseInt(e))}
              >
                <Dropdown.Toggle
                  className={`select-title ${locationId ? "selected" : ""}`}
                  style={{ height: "50px" }}
                >
                  {locationsData?.find((loc) => loc.id === locationId)
                    ?.locationName || "Select Location"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {locationsData?.map((location) => (
                    <Dropdown.Item key={location.id} eventKey={location.id}>
                      {location.locationName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* Asset Dropdown */}
            <div className="col-md-12" style={{ marginBottom: "15px" }}>
              <label className="fw-medium pb-2" style={{ marginBottom: "8px" }}>
                Faulty Asset
              </label>
              <Dropdown
                className="select__form"
                onSelect={(e) => setAssetId(parseInt(e))}
              >
                <Dropdown.Toggle
                  className={`select-title ${assetId ? "selected" : ""}`}
                  style={{ height: "50px" }}
                >
                  {assetsData?.find((asset) => asset.id === assetId)
                    ?.assetName || "Select Asset"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {assetsData?.map((asset) => (
                    <Dropdown.Item key={asset.id} eventKey={asset.id}>
                      {asset.assetName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* Faults Multi-Select Dropdown */}
            <div className="col-md-12" style={{ marginBottom: "15px" }}>
              <label className="fw-medium pb-2" style={{ marginBottom: "8px" }}>
                Faults
              </label>
              <Dropdown className="select__form">
                <Dropdown.Toggle
                  className={`select-title ${
                    faultIds.length ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {displayedText}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {faultsData?.map((fault) => (
                    <Dropdown.Item
                      key={fault.id}
                      eventKey={fault.id}
                      onClick={() => handleFaultChange(fault.id)}
                    >
                      <input
                        type="checkbox"
                        checked={faultIds.includes(fault.id)}
                        readOnly
                        style={{
                          marginRight: "10px",
                        }}
                      />
                      {fault.faultName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {faultsError && (
                <div className="invalid-feedback">
                  Please select at least one fault.
                </div>
              )}
            </div>
            {/* Fault Description */}
            <div className="col-md-12" style={{ marginBottom: "15px" }}>
              <label className="fw-medium pb-2" style={{ marginBottom: "8px" }}>
                Fault Description
              </label>
              <textarea
                className="modal-input-box"
                defaultValue={faultDescription}
                onChange={(e) => setFaultDescription(e.target.value)}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "80px",
                  borderRadius: "5px",
                  padding: "10px 15px",
                }}
                placeholder="Enter fault description"
              />
            </div>
            {/* Recurrence Radio Buttons */}
            <div className="col-md-12" style={{ marginBottom: "15px" }}>
              <label className="fw-medium pb-2" style={{ marginBottom: "8px" }}>
                Fault Recurrence
              </label>
              <div style={{ display: "flex", gap: "70px" }}>
                <div
                  style={{ display: "flex", gap: "15px", alignItems: "center" }}
                >
                  <label
                    htmlFor="firstTimeFault"
                    style={{
                      color: !recurrence ? "#D57D2A" : "#C5C7CD",
                      cursor: "pointer",
                    }}
                  >
                    {!recurrence ? (
                      <FaCircleCheck size={20} />
                    ) : (
                      <MdOutlineRadioButtonUnchecked size={20} />
                    )}
                  </label>
                  <input
                    type="radio"
                    id="firstTimeFault"
                    name="recurrence"
                    value={false}
                    checked={!recurrence}
                    onChange={() => setRecurrence(false)}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="firstTimeFault">First Time Fault</label>
                </div>

                <div
                  style={{ display: "flex", gap: "15px", alignItems: "center" }}
                >
                  <label
                    htmlFor="recurringFault"
                    style={{
                      color: recurrence ? "#D57D2A" : "#C5C7CD",
                      cursor: "pointer",
                    }}
                  >
                    {recurrence ? (
                      <FaCircleCheck size={20} />
                    ) : (
                      <MdOutlineRadioButtonUnchecked size={20} />
                    )}
                  </label>
                  <input
                    type="radio"
                    id="recurringFault"
                    name="recurrence"
                    value={true}
                    checked={recurrence}
                    onChange={() => setRecurrence(true)}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="recurringFault">Recurring Fault</label>
                </div>
              </div>
            </div>
            {/* Operational Status Dropdown */}
            <div className="col-md-12" style={{ marginBottom: "15px" }}>
              <label className="fw-medium pb-2" style={{ marginBottom: "8px" }}>
                Operational Status
              </label>
              <Dropdown
                className="select__form"
                onSelect={(e) => setOperationalStatusId(parseInt(e))}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    operationalStatusId ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {operationalStatusData?.find(
                    (status) => status.id === operationalStatusId
                  )?.operationalStatusName || "Select Operational Status"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {operationalStatusData?.map((status) => (
                    <Dropdown.Item key={status.id} eventKey={status.id}>
                      {status.operationalStatusName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <button
              className="delate-btn"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditRequestModal;
