"use client";

import * as React from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DownIcon from "../../Assets/Icons/DownIcon";
import DashboardInfoModal from "../WorkOrders/Modal/DashboardInfoModal";

const data = [
  { month: "Jan", value: 38 },
  { month: "Feb", value: 60 },
  { month: "Mar", value: 22 },
  { month: "Apr", value: 75 },
  { month: "May", value: 60 },
  { month: "Jun", value: 62 },
  { month: "Jul", value: 48 },
  { month: "Aug", value: 35 },
  { month: "Sep", value: 55 },
  { month: "Oct", value: 85 },
  { month: "Nov", value: 65 },
  { month: "Dec", value: 75 },
];

const locations = [
    "Location A",
    "Location B",
    "Location C",
    "Location D",
  ];

const dropdownButtonStyle = {
  border: "none",
  boxShadow: "0px 2px 10px 0px #00000026",
  transition: "box-shadow 0.3s ease",
};

const dropdownButtonHoverStyle = {
  ...dropdownButtonStyle,
  boxShadow: "0px 4px 15px 0px #00000040",
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-lg bg-white p-4 shadow-lg">
        <p className="text-sm text-gray-500">Monthly Performance</p>
        <p className="text-sm font-semibold">
          Closed on time <span>{payload[0].value}%</span>
        </p>
      </div>
    );
  }
  return null;
};

const CustomBar = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 8;
  const fullHeight = height / (value / 100); // Calculate full height based on the value

  return (
    <g>
      {/* Gray background (always full height) */}
      <rect
        x={x}
        y={y - (fullHeight - height)}
        width={width}
        height={fullHeight}
        fill="#F3F4F6"
        rx={radius}
        ry={radius}
      />
      {/* Orange foreground (proportional to value) */}
      <rect
        x={x}
        y={y + height - (height * value) / 100}
        width={width}
        height={(height * value) / 100}
        fill="#EE9C39"
        rx={radius}
        ry={radius}
      />
    </g>
  );
};

export default function MonthlyPerformance() {
    const [selectedLocations, setSelectedLocations] = React.useState([]);
    const [hoveredButton, setHoveredButton] = React.useState(null);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [filteredLocations, setFilteredLocations] = React.useState(locations);
    const [modalOpen, setModalOpen] = React.useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }


      
    React.useEffect(() => {
        const filtered = locations.filter((location) =>
          location.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredLocations(filtered);
      }, [searchTerm]);
    
      const handleLocationClick = (location) => {
        setSelectedLocations(prev => 
          prev.includes(location) 
            ? prev.filter(l => l !== location)
            : [...prev, location]
        );
      };

      const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
      const isSelected = (location) => selectedLocations.includes(location);
    

  return (
    <>
    <div
      className="w-full space-y-4 rounded-lg bg-white p-[40px]"
      style={{ padding: "20px 40px" }}
    >
      <style jsx>{`
        .dropdown-item:hover {
          background-color: transparent !important;
        }
        .btn-outline-secondary {
          border: none;
        }
      `}</style>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ paddingBottom: "20px" }}
      >
        <div>
          <div className="d-flex align-items-center gap-2">
            <h2 className="fs-4 fw-bold mb-0">Monthly Performance</h2>
            <p className="text-muted small mb-0">Work closed on time (%)</p>
            {/* <InfoIcon style={{ color: '#EE9C39', fontSize: '1.25rem' }} /> */}
            <div onClick={toggleModal} className="cursor-pointer">
                <img src="/info.svg" alt="" className="w-[24px] h-[24px]" />
              </div>
          </div>
        </div>
        <div className="dropdown select-dropdown">
              <button
                className="select-title"
                type="button"
                data-bs-toggle="dropdown"
                style={
                  hoveredButton === 'location' ? dropdownButtonHoverStyle : dropdownButtonStyle
                }
                onMouseEnter={() => setHoveredButton('location')}
                onMouseLeave={() => setHoveredButton(null)}
              >
                <span className="fs-12">Locations:</span>
                <span className="fs-12 d-flex align-items-center gap-1" style={{color: "#00000061"}}>
                  {selectedLocations.length === 0
                    ? "All"
                    : `${selectedLocations.length} selected`}{" "}
                  <DownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ width: "100%", padding: "10px" }}
              >
                <div
                  className="dropdown-search"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    color: "#c5c7cd",
                    position: "relative",
                    borderRadius: "5px",
                    border: "0.5px solid #f1efef",
                    background: "#fff",
                    marginBottom: "10px",
                    padding: "5px",
                    paddingLeft: "15px",
                  }}
                >
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="Search"
                    style={{ border: "none", outline: "none", width: "100%" }}
                    className="dropDownSearch"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="dropdown-item-content">
                <li >
                  <b>All</b>
                </li>
                <hr />
                  {filteredLocations.map((location, i) => (
                    <li
                      style={{
                        padding: "4px 0",
                        fontSize: "14px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      key={i}
                      onClick={() => handleLocationClick(location)}
                    >
                      {isSelected(location) ? (
                        <CheckBoxIcon style={{ color: "#D57D2A", marginRight: "4px" }} />
                      ) : (
                        <CheckBoxOutlineBlankIcon style={{ color: "#DFE0EB", marginRight: "4px", border:"1px" }} />
                      )}
                      {location}
                    </li>
                  ))}
                </div>
              </ul>
            </div>
      </div>

      <div style={{ height: "300px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
          >
            <CartesianGrid
              vertical={false}
              horizontal={true}
              strokeDasharray="3 3"
              stroke="#E5E7EB"
            />
            <XAxis
              dataKey="month"
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#6B7280", fontSize: 12 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#6B7280", fontSize: 12 }}
              domain={[0, 100]}
              ticks={[0, 20, 40, 60, 80, 100]}
            />
            <Tooltip content={<CustomTooltip />} cursor={false} />
            <Bar dataKey="value" shape={<CustomBar />} barSize={15} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
    <DashboardInfoModal show={modalOpen} toggleModal={toggleModal}/>
    </>
    
  );
}
