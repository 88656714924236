import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { useDispatch } from "react-redux";
import { addProjectedPart } from "../../../redux/formSlice";

const AddPartsModal = ({
  onHide,
  show,
  partData,
  partCategories,
  defaultValues,
  selectedParts,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedPart("Select Part");
    setQuantity("");
  }, [show]);

  const [selectedPart, setSelectedPart] = useState("Select Part");
  const [quantity, setQuantity] = useState("");

  // Part category search input
  const [partSearch, setPartSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");

  // Filter part data
  // remove parts that are already selected

  const uniqueFilteredPartData = partData?.filter(
    (obj) => !selectedParts.some((obj2) => obj.partName === obj2.partName)
  );

  const filteredPartData =
    uniqueFilteredPartData &&
    uniqueFilteredPartData?.filter((item) => {
      const uniqueData = !selectedParts.some((part) => part.partId === item.id);

      return item.partName.toLowerCase().includes(partSearch.toLowerCase());
    });

  const filteredCategoryData = partCategories?.filter((item) => {
    return item?.name.toLowerCase().includes(categorySearch.toLowerCase());
  });

  const handlePartSelect = (eventKey) => {
    setSelectedPart(eventKey);
    setPartSearch("");
  };

  const handleAddPart = () => {
    const part = partData?.find((item) => item.partName === selectedPart);

    if (part && quantity) {
      const partObject = {
        partId: part.id,
        partName: part.partName,
        quantity: Number(quantity),
      };

      dispatch(addProjectedPart(partObject));
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Parts</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Select parts and quantities required.
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            {/* <div className="col-md-12">
              <div className="d-flex align-items-center gap-4">
                <span className="fs-16 fw-bold pb-2">Select Part Category</span>
                <span
                  className="fs-14 pb-2 "
                  style={{
                    color: "#D57D2A",
                    textDecoration: "underline",
                  }}
                >
                  Add New
                </span>
              </div>

              <Dropdown className="select__form" onSelect={handlePartSelect}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedPart !== "Select Part" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectedPart}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={partSearch}
                      onChange={(e) => setPartSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredCategoryData &&
                      filteredCategoryData.map((item, index) => (
                        <Dropdown.Item key={index} eventKey={item.partName}>
                          {item.name}
                        </Dropdown.Item>
                      ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            <div className="col-md-12">
              <label className="fw-medium pb-2">Select Part</label>
              <Dropdown className="select__form" onSelect={handlePartSelect}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedPart !== "Select Part" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectedPart}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={partSearch}
                      onChange={(e) => setPartSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredPartData &&
                      filteredPartData.map((item, index) => (
                        <Dropdown.Item key={index} eventKey={item.partName}>
                          {item.partName}
                        </Dropdown.Item>
                      ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <label className="fw-medium pb-2">Quantity</label>
              <input
                className="modal-input-box"
                type="text"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter quantity required"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={onHide}>
              Cancel
            </button>
            <button className="delate-btn" onClick={handleAddPart}>
              Add
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddPartsModal;
