/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@tanstack/react-query';
import { axiosInterceptor } from '../utls/axiosInterceptor';

const usefetchData = (
  queryKey,
  url,
  config,
  errorMessage,
  dependency = true,
  noCache = false
) => {
  

  const queryFunction = async () => {
    const response = await axiosInterceptor.get(url, config)
    return response.data;
  };

  const { data, isLoading, error, status } = useQuery({
    queryKey,
    queryFn: queryFunction,
    staleTime: noCache ? 0 : 3600 * 60,
    cacheTime: noCache ? 0 : 3600 * 1000,
    enabled: dependency,
    onError: () => {
      console.error(errorMessage);
    },
  });

  

  return { data, isLoading, error, status };
};

export default usefetchData;
