import React, { useEffect, useState } from "react";
import "../Components/WorkOrders/WorkOrders.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Link, NavLink, Outlet } from "react-router-dom";
import WorkHeader from "../Components/WorkOrders/WorkHeader";
import { get } from "../Services/ApiHelper";
import {
  GET_ALL_TICKET_COUNT,
  GET_CLOSED_WORKORDERS,
  GET_NEW_WORKORDERS,
  GET_ONGOING_WORKORDERS,
  GET_PENDING_WORKORDERS,
  GET_REJECTED_WORKORDERS,
} from "../utls/constants";

const WorkOrders = () => {
  const [report, setReport] = useState([]);
  const [action, setAction] = useState([]);
  const [count, setCount] = useState(0);
  const [newS, setNewS] = useState(true);
  const [pending, setPending] = useState(false);
  const [ongoing, setOngoing] = useState(false);
  const [closed, setClosed] = useState(false);
  const [reject, setReject] = useState(false);
  const [resetCount, setResetCount] = useState(false);
  const [resetCountNew, setResetCountNew] = useState(false);
  const ticketType = "corrective";

  const bearerToken = localStorage.getItem("bearerToken");
  useEffect(() => {
    handleNewWorkOrderRepotrt();
  }, []);

  const countReset = () => {
    get(GET_ALL_TICKET_COUNT(ticketType), localStorage.getItem("bearerToken"))
      .then((response) => {
        setCount(response);
        console.log(response);
      })
      .catch((error) => {
        console.log("An Error Ocurred");
      });
  }

  const handleNewWorkOrderRepotrt = () => {
    setNewS(true);
    setPending(false);
    setOngoing(false);
    setClosed(false);
    setReject(false);
    countReset()
    get(GET_NEW_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setAction("New_CM_Work");

        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };

  const handleRejectedWorkOrderRepotrt = () => {
    setNewS(false);
    setPending(false);
    setOngoing(false);
    setClosed(false);
    setReject(true);
    countReset()
    get(GET_REJECTED_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setAction("Rejected_CM_Work");

        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };
  const handleOngoingWorkOrderRepotrt = () => {
    setNewS(false);
    setPending(false);
    setOngoing(true);
    setClosed(false);
    setReject(false);
    countReset()
    get(GET_ONGOING_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setAction("Ongoing_CM_Work");

        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };
  const handlePendingClosureWorkOrderRepotrt = () => {
    setNewS(false);
    setPending(true);
    setOngoing(false);
    setClosed(false);
    setReject(false);
    countReset()
    get(GET_PENDING_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setAction("Pending_Closure_CM_Work");

        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };
  const handleClosedWorkOrderRepotrt = () => {
    setNewS(false);
    setPending(false);
    setOngoing(false);
    setClosed(true);
    setReject(false);
    countReset()
    get(GET_CLOSED_WORKORDERS("", 1000, 1), bearerToken)
      .then((response) => {
        setAction("Closed_CM_Work");
        setReport(response["data"]);
      })
      .catch((error) => console.log("An error has occured"));
  };

  const handleUpcomingWorkOrderRepotrt = () => {
    setReport([]);
  };
  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      if (claimsArray.includes(claimValue)) {
        // The string is present in the array
        return true;
      } else {
        return false;
      }
    } else {
      // Handle the case where the "claims" key is not present in local storage
      return false;
    }
  };

  const ticketCounts = count
    ? count.reduce((acc, { ticketStatusName, ticketCount }) => {
        acc[ticketStatusName.toLowerCase()] = ticketCount;
        return acc;
      }, {})
    : {};

  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <WorkHeader report={report} action={action} />
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="">CM Work</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inspection-cate">
              <div className="work-table-nav">
                <NavLink
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                  to="/work-orders"
                  onClick={() => {
                    handleNewWorkOrderRepotrt();
                  }}
                  end
                >
                  <span>New Work</span>
                  {ticketCounts["new"] >= 0 ? (
                    <span
                      style={{
                        width: "50px",
                        height: "25px",
                        backgroundColor: newS ? "#D57D2A" : "#6C5B51",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        borderRadius: "36.5px",
                      }}
                    >
                      {ticketCounts["new"]}
                    </span>
                  ) : (
                    ""
                  )}
                </NavLink>
                <NavLink
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                  to="/work-orders/ongoing-work"
                  onClick={() => {
                    handleOngoingWorkOrderRepotrt();
                  }}
                >
                  <span>Ongoing Work</span>
                  {ticketCounts["ongoing"] >= 0 ? (
                    <span
                      style={{
                        width: "50px",
                        height: "25px",
                        backgroundColor: ongoing ? "#D57D2A" : "#6C5B51",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        borderRadius: "36.5px",
                      }}
                    >
                      {ticketCounts["ongoing"]}
                    </span>
                  ) : (
                    ""
                  )}
                </NavLink>
                <NavLink
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                  to="/work-orders/pending-closure"
                  onClick={() => {
                    handlePendingClosureWorkOrderRepotrt();
                  }}
                >
                  <span>Pending Closure</span>
                  {ticketCounts["pendingclosure"] >= 0 ? (
                    <span
                      style={{
                        width: "50px",
                        height: "25px",
                        backgroundColor: pending ? "#D57D2A" : "#6C5B51",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        borderRadius: "36.5px",
                      }}
                    >
                      {ticketCounts["pendingclosure"]}
                    </span>
                  ) : (
                    ""
                  )}
                </NavLink>
                <NavLink
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                  to="/work-orders/closed-work"
                  onClick={() => {
                    handleClosedWorkOrderRepotrt();
                  }}
                >
                  <span>Closed Work</span>
                  {ticketCounts["closed"] >= 0 ? (
                    <span
                      style={{
                        width: "50px",
                        height: "25px",
                        backgroundColor: closed ? "#D57D2A" : "#6C5B51",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        borderRadius: "36.5px",
                      }}
                    >
                      {ticketCounts["closed"]}
                    </span>
                  ) : (
                    ""
                  )}
                </NavLink>
                {claims("Can_View_RejectedWork") && (
                  <NavLink
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                    to="/work-orders/rejected-work"
                    onClick={() => {
                      handleRejectedWorkOrderRepotrt();
                    }}
                  >
                    <span>Rejected Work</span>
                    {ticketCounts["rejected"] >= 0 ? (
                      <span
                        style={{
                          width: "50px",
                          height: "25px",
                          backgroundColor: "red",
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderRadius: "36.5px",
                        }}
                      >
                        {ticketCounts["rejected"]}
                      </span>
                    ) : (
                      ""
                    )}
                  </NavLink>
                )}
                {/* <NavLink to="/work-orders/upcoming-work" onClick={()=>{
                  handleUpcomingWorkOrderRepotrt()
                }}>Upcoming Work</NavLink> */}
              </div>
              <div className="work-table-tables">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrders;
