import { useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DashboardInfoModal from "../WorkOrders/Modal/DashboardInfoModal";

const dropdownButtonStyle = {
  border: "none",
  boxShadow: "0px 2px 10px 0px #00000026",
  transition: "box-shadow 0.3s ease",
};

const dropdownButtonHoverStyle = {
  ...dropdownButtonStyle,
  boxShadow: "0px 4px 15px 0px #00000040",
};

const periods = [
  "Current",
  "Last Week",
  "Last Month",
  "Last Quarter",
  "Last Year",
];

const locations = ["Location A", "Location B", "Location C", "Location D"];

export default function CorrectiveWork() {
  const [selectedPeriod, setSelectedPeriod] = useState("Current");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPeriods, setFilteredPeriods] = useState(periods);
  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }


  useEffect(() => {
    const filtered = locations.filter((location) =>
      location.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLocations(filtered);
  }, [searchTerm]);

  const handleLocationClick = (location) => {
    setSelectedLocations((prev) =>
      prev.includes(location)
        ? prev.filter((l) => l !== location)
        : [...prev, location]
    );
  };

  const isSelected = (location) => selectedLocations.includes(location);

  useEffect(() => {
    const filtered = periods.filter((period) =>
      period.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPeriods(filtered);
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const stats = {
    total: 10200,
    new: 2200,
    ongoing: 5900,
    closed: 4400,
    overdue: 3450,
  };

  return (
    <>
    <div className="card " style={{ padding: "17px 34px" }}>
      <div className="card-body p-0">
        <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="fs-20 fw-bold card-title mb-0 d-flex align-items-center gap-2">
              Corrective Work
              <div onClick={toggleModal} className="cursor-pointer">
                <img src="/info.svg" alt="" className="w-[24px] h-[24px]" />
              </div>
            </h5>
          </div>
          <div className="d-flex gap-3">
            <div className="dropdown select-dropdown">
              <button
                className="select-title"
                type="button"
                data-bs-toggle="dropdown"
                style={
                  hoveredButton === "period"
                    ? dropdownButtonHoverStyle
                    : dropdownButtonStyle
                }
                onMouseEnter={() => setHoveredButton("period")}
                onMouseLeave={() => setHoveredButton(null)}
              >
                <span className="fs-12">Period:</span>
                <span
                  className="fs-12 d-flex align-items-center gap-1 text-[##00000061]"
                  style={{ color: "#00000061" }}
                >
                  {selectedPeriod}{" "}
                  <DownIcon className="fs-12" style={{ color: "#000000" }} />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ width: "100%", padding: "10px" }}
              >
                <div
                  className="dropdown-search"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    color: "#c5c7cd",
                    position: "relative",
                    borderRadius: "5px",
                    border: "0.5px solid #f1efef",
                    background: "#fff",
                    marginBottom: "10px",
                    padding: "5px",
                    paddingLeft: "15px",
                  }}
                >
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="Search"
                    style={{ border: "none", outline: "none", width: "100%" }}
                    className="dropDownSearch"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
                <li onClick={() => setSelectedPeriod("All")}>
                  <b>All</b>
                </li>
                <hr />
                <div className="dropdown-item-content">
                  {filteredPeriods.map((period, i) => (
                    <li
                      style={{
                        padding: "6px 0px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      key={i}
                      onClick={() => setSelectedPeriod(period)}
                    >
                      {period}
                    </li>
                  ))}
                </div>
              </ul>
            </div>
            <div className="dropdown select-dropdown">
              <button
                className="select-title"
                type="button"
                data-bs-toggle="dropdown"
                style={
                  hoveredButton === "location"
                    ? dropdownButtonHoverStyle
                    : dropdownButtonStyle
                }
                onMouseEnter={() => setHoveredButton("location")}
                onMouseLeave={() => setHoveredButton(null)}
              >
                <span className="fs-12">Locations:</span>
                <span
                  className="fs-12 d-flex align-items-center gap-1"
                  style={{ color: "#00000061" }}
                >
                  {selectedLocations.length === 0
                    ? "All"
                    : `${selectedLocations.length} selected`}{" "}
                  <DownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ width: "100%", padding: "10px" }}
              >
                <div
                  className="dropdown-search"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    color: "#c5c7cd",
                    position: "relative",
                    borderRadius: "5px",
                    border: "0.5px solid #f1efef",
                    background: "#fff",
                    marginBottom: "10px",
                    padding: "5px",
                    paddingLeft: "15px",
                  }}
                >
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="Search"
                    style={{ border: "none", outline: "none", width: "100%" }}
                    className="dropDownSearch"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="dropdown-item-content">
                  <li onClick={() => setSelectedPeriod("All")}>
                    <b>All</b>
                  </li>
                  <hr />
                  {filteredLocations.map((location, i) => (
                    <li
                      style={{
                        padding: "4px 0",
                        fontSize: "14px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      key={i}
                      onClick={() => handleLocationClick(location)}
                    >
                      {isSelected(location) ? (
                        <CheckBoxIcon
                          style={{ color: "#D57D2A", marginRight: "4px" }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankIcon
                          style={{
                            color: "#DFE0EB",
                            marginRight: "4px",
                            border: "1px",
                          }}
                        />
                      )}
                      {location}
                    </li>
                  ))}
                </div>
              </ul>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex flex-column align-items-start">
                <h2 className="display-5 fs-28 fw-bold mb-0">
                  {stats.total.toLocaleString()}
                </h2>
                <span className=" fs-16">Work Items</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column fs-12">
                <div className="d-flex align-items-center gap-2 mb-2">
                  <span
                    className="bullet bg-[#6C5B51] rounded-circle"
                    style={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "#6C5B51",
                    }}
                  ></span>
                  <span>{stats.new.toLocaleString()} New</span>
                </div>
                <div className="d-flex align-items-center gap-2 mb-2">
                  <span
                    className="bullet bg-[#D57D2A] rounded-circle"
                    style={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "#D57D2A",
                    }}
                  ></span>
                  <span>{stats.ongoing.toLocaleString()} Ongoing</span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <span
                    className="bullet bg-[#EE9C39] rounded-circle"
                    style={{
                      width: "8px",
                      height: "8px",
                      backgroundColor: "#EE9C39",
                    }}
                  ></span>
                  <span>{stats.closed.toLocaleString()} Closed</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-top pt-3 text-center">
          <div className="text-muted mb-2">Overdue Work</div>
          <h3 className="fw-bold mb-0">{stats.overdue.toLocaleString()}</h3>
        </div>
      </div>
    </div>
    <DashboardInfoModal show={modalOpen} toggleModal={toggleModal} />
    </>
    
  );
}
