"use client";

import React, { useState, useEffect } from "react";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DashboardInfoModal from "../WorkOrders/Modal/DashboardInfoModal";

const dropdownButtonStyle = {
  border: "none",
  boxShadow: "0px 2px 10px 0px #00000026",
  transition: "box-shadow 0.3s ease",
};

const dropdownButtonHoverStyle = {
  ...dropdownButtonStyle,
  boxShadow: "0px 4px 15px 0px #00000040",
};

const periods = [
    "Current",
    "Last Week",
    "Last Month",
    "Last Quarter",
    "Last Year",
  ];
  
  const locations = [
    "Location A",
    "Location B",
    "Location C",
    "Location D",
  ];

function StatsCard({ title, value, change, changeType, period }) {
  return (
    <div className="" style={{ paddingTop: "15px", paddingBottom: "15px" }}>
      <p className="fs-24 fw-bold mb-0">{value} Work</p>
      <p className="text-muted fs-16 small mb-2">{title}</p>
      <div className="d-flex align-items-center small">
        {changeType === "increase" ? (
          <CallMadeIcon style={{ color: "#D57D2A", fontSize: "1rem" }} />
        ) : (
          <CallReceivedIcon style={{ color: "#6C5B51", fontSize: "1rem" }} />
        )}
        <span
          className={`ms-1 fs-14 ${
            changeType === "increase" ? "text-[#7C8DB5]" : "text-{#7C8DB5}"
          }`}
        >
          {change}
        </span>
        <span className="text-muted ms-1">{period}</span>
      </div>
    </div>
  );
}

export default function PerformanceStats() {
    const [selectedPeriod, setSelectedPeriod] = useState("Current");
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [hoveredButton, setHoveredButton] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredPeriods, setFilteredPeriods] = useState(periods);
    const [filteredLocations, setFilteredLocations] = useState(locations);
    const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  
    useEffect(() => {
      const filtered = locations.filter((location) =>
        location.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredLocations(filtered);
    }, [searchTerm]);
  
    const handleLocationClick = (location) => {
      setSelectedLocations(prev => 
        prev.includes(location) 
          ? prev.filter(l => l !== location)
          : [...prev, location]
      );
    };
  
    const isSelected = (location) => selectedLocations.includes(location);
  
    useEffect(() => {
      const filtered = periods.filter((period) =>
        period.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPeriods(filtered);
    }, [searchTerm]);
  
    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };
  return (
    <>
    <div style={{ padding: "17px 34px" }}>
      <div className="mb-4">
        <div className="d-flex align-items-center mb-2">
          <h5 className="mb-0 me-2 fs-20 fw-bold">
            Performance Stats - All Work
          </h5>
          {/* <InfoIcon style={{ color: '#ffa500', fontSize: '1rem' }} /> */}
          <div onClick={toggleModal} className="cursor-pointer">
                <img src="/info.svg" alt="" className="w-[24px] h-[24px]" />
              </div>
        </div>
        <div className="d-flex gap-3">
            <div className="dropdown select-dropdown">
              <button
                className="select-title"
                type="button"
                data-bs-toggle="dropdown"
                style={
                  hoveredButton === "period"
                    ? dropdownButtonHoverStyle
                    : dropdownButtonStyle
                }
                onMouseEnter={() => setHoveredButton("period")}
                onMouseLeave={() => setHoveredButton(null)}
              >
                <span className="fs-12">Period:</span>
                <span
                  className="fs-12 d-flex align-items-center gap-1 text-[##00000061]"
                  style={{ color: "#00000061" }}
                >
                  {selectedPeriod}{" "}
                  <DownIcon className="fs-12" style={{ color: "#000000" }} />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ width: "100%", padding: "10px" }}
              >
                <div
                  className="dropdown-search"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    color: "#c5c7cd",
                    position: "relative",
                    borderRadius: "5px",
                    border: "0.5px solid #f1efef",
                    background: "#fff",
                    marginBottom: "10px",
                    padding: "5px",
                    paddingLeft: "15px",
                  }}
                >
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="Search"
                    style={{ border: "none", outline: "none", width: "100%" }}
                    className="dropDownSearch"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
                <li onClick={() => setSelectedPeriod("All")}>
                  <b>All</b>
                </li>
                <hr />
                <div className="dropdown-item-content">
                  {filteredPeriods.map((period, i) => (
                    <li
                      style={{
                        padding: "6px 0px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      key={i}
                      onClick={() => setSelectedPeriod(period)}
                    >
                      {period}
                    </li>
                  ))}
                </div>
              </ul>
            </div>
            <div className="dropdown select-dropdown">
              <button
                className="select-title"
                type="button"
                data-bs-toggle="dropdown"
                style={
                  hoveredButton === "location"
                    ? dropdownButtonHoverStyle
                    : dropdownButtonStyle
                }
                onMouseEnter={() => setHoveredButton("location")}
                onMouseLeave={() => setHoveredButton(null)}
              >
                <span className="fs-12">Locations:</span>
                <span
                  className="fs-12 d-flex align-items-center gap-1"
                  style={{ color: "#00000061" }}
                >
                  {selectedLocations.length === 0
                    ? "All"
                    : `${selectedLocations.length} selected`}{" "}
                  <DownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ width: "100%", padding: "10px" }}
              >
                <div
                  className="dropdown-search"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    color: "#c5c7cd",
                    position: "relative",
                    borderRadius: "5px",
                    border: "0.5px solid #f1efef",
                    background: "#fff",
                    marginBottom: "10px",
                    padding: "5px",
                    paddingLeft: "15px",
                  }}
                >
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="Search"
                    style={{ border: "none", outline: "none", width: "100%" }}
                    className="dropDownSearch"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="dropdown-item-content">
                  <li onClick={() => setSelectedPeriod("All")}>
                    <b>All</b>
                  </li>
                  <hr />
                  {filteredLocations.map((location, i) => (
                    <li
                      style={{
                        padding: "4px 0",
                        fontSize: "14px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      key={i}
                      onClick={() => handleLocationClick(location)}
                    >
                      {isSelected(location) ? (
                        <CheckBoxIcon
                          style={{ color: "#D57D2A", marginRight: "4px" }}
                        />
                      ) : (
                        <CheckBoxOutlineBlankIcon
                          style={{
                            color: "#DFE0EB",
                            marginRight: "4px",
                            border: "1px",
                          }}
                        />
                      )}
                      {location}
                    </li>
                  ))}
                </div>
              </ul>
            </div>
          </div>
      </div>
      <div className="row gy-4">
        <div
          className="col-md-6 pe-md-3"
          style={{ borderRight: "1px solid #dee2e6" }}
        >
          <h6
            className=" mb-3 fw-bold text-[#00000066]"
            style={{ color: "#00000066" }}
          >
            MY LOCATIONS
          </h6>
          <hr />
          <StatsCard
            title="Closed on time"
            value="55%"
            change="+1.01%"
            changeType="increase"
            period="Last 7 days"
          />
          <hr className="my-0" />
          <StatsCard
            title="Not closed on time"
            value="25%"
            change="-0.91%"
            changeType="decrease"
            period="Last 7 days"
          />
        </div>
        <div className="col-md-6 ps-md-3">
          <h6 className=" mb-3 fw-bold" style={{ color: "#00000066" }}>
            OVERALL
          </h6>
          <hr />
          <StatsCard
            title="Closed on time"
            value="85%"
            change="+2.01%"
            changeType="increase"
            period="Last 7 days"
          />
          <hr className="my-0" />
          <StatsCard
            title="Not closed on time"
            value="10%"
            change="-3.91%"
            changeType="decrease"
            period="Last 7 days"
          />
        </div>
      </div>
    </div>
    <DashboardInfoModal show={modalOpen} toggleModal={toggleModal}/>
    </>
    
  );
}
