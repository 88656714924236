import React from "react";
import { useReactToPrint } from "react-to-print";
import DownloadIcon from "../../../Assets/Icons/DownloadIcon";
import PDFDocument from "./PDFDocument";

const QuotesDownload = ({ documentData }) => {
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("quotes-pad"),
  });

  return (
    <>
      <div className="quotes-download">
        <div className="fs-15 pb-4 pt-1">
          {/* <b>Contract Type:</b> In Contract */}
        </div>
        <div className="quotes-head d-flex flex-wrap justify-content-between">
          <div className="fs-15 text-black">DOCUMENT VIEW</div>
          <div className="d-flex gap-4 pt-2 pt-lg-0">
            <button onClick={handlePrint} className="fs-15 text-black fw-bold">
              Download Quote <DownloadIcon />
            </button>
          </div>
        </div>
        <PDFDocument quotesData={documentData} />
      </div>
    </>
  );
};

export default QuotesDownload;
