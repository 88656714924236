/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../Assets/Icons/ViewIcon2";
import DelateIcon2 from "../../Assets/Icons/DelateIcon2";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../Assets/Icons/DropdownIcon";
import getUniqueProperty from "../../utls/getUniqueProprty";
import filterViaProperty from "../../utls/filterViaProperty";
import searchAllProperty from "../../utls/searchAllProperty";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import DeleteModal from "../WorkOrders/Modal/DeleteModal";
import usefetchData from "../../hooks/useFetchData";
import qs from "qs";
import useMutateData from "../../hooks/useMutateData";
import { toast } from "react-toastify";
import { flattenObject } from "../../utls/flattenObject";
import { Dropdown } from "react-bootstrap";

const LocationsTab = () => {
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedSubmitted, setSelectedSubmitted] = useState("");
  const [sortBy, setSortBy] = useState("location");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [RequestsPerPage, setRequestsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [RequestsShow, setRequestsShow] = useState(false);
  const [filters, setFilters] = useState({});
  const [locationId, setLocationId] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [selectedLocation, setSelectedLocation] = useState(""); //Location
  const [searchLocation, setSearchLocation] = useState("");

  const { data, isLoading, isError } = usefetchData(
    ["locations-data", filters],
    `/Locations/GetPaginatedLocations`,
    {
      params: {
        ...filters,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
    "Failed to get locations-data",
    true,
    true
  );

  // get location filter data
  const { data: locationData, isLoading: isLoadingLocation } = usefetchData(
    ["location-regions"],
    `/Locations/GetAllRegions`,
    {},
    "Error getting location data",
    true
  );

  // handle filter change to refetch data
  useEffect(() => {
    const filterObject = {
      filter: {
        RegionId: locationId,
      },
      pageNumber: currentPage,
      pageSize,
      sortOrder,
      search,
    };

    setFilters(flattenObject(filterObject));
  }, [locationId, pageSize, currentPage, sortOrder, search]);

  // delete location mutation
  const { mutate, isLoading: isDeletingLocation } = useMutateData({
    url: `/Locations/DeleteLocation`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setRequestsShow(false); // Hide delete modal
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully recalled costing`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["locations-data", filters]],
  });

  const onDelete = async () => {
    const requestBody = {
      id: locationId,
    };
    mutate(requestBody);
  };

  const sortedData = data?.data?.sort((a, b) => {
    if (sortBy === "location") {
      return sortOrder === "asc"
        ? a.locationName.localeCompare(b.locationName)
        : b.locationName.localeCompare(a.locationName);
    }
    if (sortBy === "address") {
      return sortOrder === "asc"
        ? a.locationAddress.localeCompare(b.locationAddress)
        : b.locationAddress.localeCompare(a.locationAddress);
    }
    if (sortBy === "region") {
      return sortOrder === "asc"
        ? a.regionName.localeCompare(b.regionName)
        : b.regionName.localeCompare(a.regionName);
    }
    return 0; // Default return if no sorting is applied
  });

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  // handle state setting for location filters
  const handleLocationClick = (location) => {
    setSelectedLocation(location.regionName);
    setLocationId(location.id);
  };

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
  };

  const handleSubmittedClick = (submitted) => {
    setSelectedSubmitted(submitted);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (isError) {
    return <div>Error loading data</div>;
  }

  const totalPages = data?.totalPages;

  return (
    <>
      <div className="selection-grope">
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Grouping</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {selectedLocation} <DownIcon />
            </span>
          </button>

          <ul className="dropdown-menu select-menu">
            {/* Search Form */}
            <form className="dropdown-search">
              <button disabled>
                <SearchIcon />
              </button>
              <input
                onChange={(e) => setSearchLocation(e.target.value)} // Handle search input
                value={searchLocation}
                type="text"
                placeholder="Search Region"
              />
            </form>

            <li onClick={() => handleLocationClick("")}>
              <b>All</b>
            </li>
            <hr />

            <div className="dropdown-item-content">
              {/* Filter the location data based on search input */}
              {!isLoadingLocation &&
                locationData?.length > 0 &&
                locationData
                  .filter(
                    (location) =>
                      location?.regionName
                        ?.toLowerCase()
                        .includes(searchLocation.toLowerCase()) // Filter logic
                  )
                  .map((location, i) => (
                    <li key={i} onClick={() => handleLocationClick(location)}>
                      {location?.regionName}
                    </li>
                  ))}
            </div>
          </ul>
        </div>

        <form action="" className="search__box">
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            placeholder="Search"
          />
        </form>
      </div>
      <div className="inspection-tables-content">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th
                  className={
                    sortBy === "location"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("location")}
                >
                  <span>Name</span>
                </th>
                <th
                  className={
                    sortBy === "address"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("address")}
                >
                  <span>Address</span>
                </th>
                <th
                  className={
                    sortBy === "region"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("region")}
                >
                  <span>Region</span>
                </th>
                <th>Parent Location</th>
                <th>In-charge</th>
                <th>Coordinates</th>
                <th className="table-th"></th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                sortedData?.length > 0 &&
                sortedData?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.locationName}</td>
                    <td>{item.locationAddress}</td>
                    <td>{item.regionName}</td>
                    <td>{item.parentLocationName}</td>
                    <td>{item.inChargeName}</td>
                    <td>
                      {item?.latitude ?? ""}
                      <br />
                      {item?.longitude ?? ""}
                    </td>
                    <td>
                      <div className="table-last-btn">
                        <div className="dropdown table-edit-dropdown">
                          <button className="" data-bs-toggle="dropdown">
                            <HiDotsVertical />
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/locations/view-location/${item.id}`}
                              >
                                <ViewIcon2 /> View
                              </Link>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  setLocationId(item.id);
                                  setRequestsShow(true);
                                }}
                              >
                                <DelateIcon2 /> Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="table-pagination pagination-center">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {RequestsPerPage} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => setRequestsPerPage(5)}>5</li>
                <li onClick={() => setRequestsPerPage(10)}>10</li>
                <li onClick={() => setRequestsPerPage(15)}>15</li>
                <li onClick={() => setRequestsPerPage(20)}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">
              {currentPage} of {totalPages}
            </div>
            <div className="arrow-btn">
              <button
                onClick={() =>
                  currentPage !== 1 && handlePageChange(currentPage - 1)
                }
              >
                <ArrowLeft />
              </button>
              <button
                onClick={() =>
                  totalPages !== currentPage &&
                  handlePageChange(currentPage + 1)
                }
              >
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={RequestsShow}
        onHide={() => setRequestsShow(false)}
        onDelete={onDelete}
        isDeleting={isDeletingLocation}
      />
    </>
  );
};

export default LocationsTab;
