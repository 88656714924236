/*werrtyuio*/
import React, { useEffect, useState } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import getUniqueProperty from "../../utls/getUniqueProprty";
import filterViaProperty from "../../utls/filterViaProperty";
import searchAllProperty from "../../utls/searchAllProperty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import DelateIcon2 from "../../Assets/Icons/DelateIcon2";
import EditIcon2 from "../../Assets/Icons/EditIcon2";
import { Link } from "react-router-dom";
import ViewIcon2 from "../../Assets/Icons/ViewIcon2";
import { HiDotsVertical } from "react-icons/hi";
import DropdownIcon from "../../Assets/Icons/DropdownIcon";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import DeleteModal from "./Modal/DeleteModal";
import { RequestService } from "../../Services/ApiServiceCalls/RequestService";
import { get, post } from "../../Services/ApiHelper";
import {
  DELETE_REQUESTS,
  GET_REQUESTS,
  GET_SIMPLE_LOCATION_LIST,
  SORT_NEW_REQUEST_BY_END_DATE,
  SORT_NEW_REQUEST_BY_LOCATION_LIST,
  SORT_NEW_REQUEST_BY_START_DATE,
  SORT_NEW_REQUEST_BY_SUBMITTED_BY,
} from "../../utls/constants";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import usefetchData from "../../hooks/useFetchData";

const TabNewRequests = ({ setIsLoadingNew, isLoadingNew, tabActive }) => {
  const requestService = RequestService();
  const [RequestsData, setRequestsData] = useState([]);
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dataNotFound = "No data found";
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [allResponses, setAllResponses] = useState(null);
  const [RequestsPerPage, setRequestsPerPage] = useState(5); //for pagination
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [locationId, setLocationId] = useState(null);
  const [submittedById, setSubmittedById] = useState(null);
  const [me, setMe] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [shortListLocations, setShortListLocations] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [modifiedById, setModifiedById] = useState(null);

  // const { data: locationData, isLoading: isLoadingLocation } = usefetchData(
  //   ["location"],
  //   `/Locations/GetSimpleLocationList`,
  //   {},
  //   "Error getting location data",
  //   true
  // );

  useEffect(() => {
    get(GET_SIMPLE_LOCATION_LIST, localStorage.getItem("bearerToken"))
      .then((response) => {
        setShortListLocations(response);
      })
      .catch((err) => {});
  }, []);

  

  // useEffect(() => {
  //   // requestService.fetchRequest(10, 1, 1, "");
  //   // setRequestsData(requestService.requests);
  //   // setIsLoading(requestService.isLoading);
  //   // setError(requestService.error);
  //   get(
  //     GET_REQUESTS(RequestsPerPage, currentPage, "Pending", debouncedSearch),
  //     localStorage.getItem("bearerToken")
  //   )
  //     .then((response) => {
  //       setRequestsData(response["data"]);
  //       setIsLoading(false);
  //       setAllResponses(response);
  //     })
  //     .catch((error) => console.log("An error occured"));
  // }, [debouncedSearch, RequestsPerPage, currentPage, me]);

  const formatDateToDDMMYY = (date) => {
    if (!date) return "";
    const dateObj = typeof date === "string" ? new Date(date) : date;

    if (isNaN(dateObj.getTime())) {
      throw new Error("Invalid date");
    }

    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is 0-based
    const year = dateObj.getFullYear();

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (tabActive === "new-requests") {
      setIsLoadingNew(true);

      const formattedStartDate = startDate ? formatDateToDDMMYY(startDate) : "";
      const formattedEndDate = endDate ? formatDateToDDMMYY(endDate) : "";

      const payLoadStartDate = startDate ? startDate.toISOString() : "";
      const payLoadEndDate = endDate ? endDate.toISOString() : "";

      get(
        GET_REQUESTS(
          RequestsPerPage,
          currentPage,
          "Pending",
          debouncedSearch,
          payLoadStartDate,
          payLoadEndDate,
          locationId,
          submittedById,
          modifiedById, // Add modifiedById here if needed
          sortOrder // Add the sortOrder parameter here
        ),
        localStorage.getItem("bearerToken")
      )
        .then((response) => {
          setRequestsData(response["data"]);
          setIsLoadingNew(false);
          setAllResponses(response);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoadingNew(false);
        });
    }
  }, [
    debouncedSearch,
    RequestsPerPage,
    currentPage,
    startDate,
    endDate,
    locationId,
    submittedById,
    modifiedById, // Add modifiedById here if needed
    sortOrder, // Add sortOrder to the dependency array if it’s dynamic
    tabActive,
  ]);

  const handleLocationClick = (locationId) => {
    setLocationId(locationId);
  };

  const handleSubmittedClick = (submittedById) => {
    setSubmittedById(submittedById);
  };

  /* const [selectedAsset, setSelectedAsset] = useState(""); */ //Asset Select
  const [selectedLocation, setSelectedLocation] = useState(""); //Location Select
  const [selectedSubmitted, setSelectedSubmitted] = useState(""); //Submitted Select
  const [selectedDeclined, setSelectedDeclined] = useState("");
  const [sortBy, setSortBy] = useState(null);

  const [search, setSearch] = useState(""); //for search
  const [RequestsShow, setRequestsShow] = useState(false); //for modal

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
    
  };

  const sortedRequestsData = RequestsData?.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA?.localeCompare(valueB)
        : valueB?.localeCompare(valueA);
    }
    return 0;
  });

  /* const handleAssetClick = (team) => {
      setSelectedAsset(team);
    }; */

  //get request locations & filter by location
  const locations = getUniqueProperty(
    sortedRequestsData,
    "locationId",
    "locationName"
  );

  const filterByLocation = filterViaProperty(
    sortedRequestsData,
    selectedLocation,
    "location"
  );

  //filter via start date if date is not null
  const filteredByStartDate = startDate
    ? filterByLocation?.filter((item) => {
        const date = new Date(item.submitDate);
        return date.getTime() >= startDate.getTime();
      })
    : filterByLocation;

  //filter via end date if date is not null
  const filteredByEndDate = endDate
    ? filteredByStartDate?.filter((item) => {
        const date = new Date(item.submitDate);
        return date.getTime() <= endDate.getTime();
      })
    : filteredByStartDate;

  // get unique submitted by & filter by submitted by
  const submittedBy = getUniqueProperty(
    sortedRequestsData,
    "submittedById",
    "submittedBy"
  );
  const filteredBySubmitted = filterViaProperty(
    filteredByEndDate,
    selectedSubmitted,
    "submitBy"
  );

  // filter via search
  const filteredBySearch = searchAllProperty(filteredBySubmitted, search);

  //for pagination
  const indexOfLastRequests = currentPage * RequestsPerPage;
  const indexOfFirstRequests = indexOfLastRequests - RequestsPerPage;
  const currentRequests = filteredBySearch?.slice(
    indexOfFirstRequests,
    indexOfLastRequests
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredBySearch?.length / RequestsPerPage);

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // const handleDelete = async () => {
  //   try {

  //     const bearerToken = localStorage.getItem("bearerToken")

  //     const config = {
  //       headers: {
  //         'Authorization': Bearer ${bearerToken}
  //       }
  //     };

  //     await axios.post('https://saharadeskbackend.azurewebsites.net/api/Requests/Delete', {
  //       id: itemIdToDelete
  //     }, config);

  //     setRequestsData(prevRequestsData => prevRequestsData.filter(item => item.id !== itemIdToDelete));
  //     setRequestsShow(!RequestsShow);
  //   } catch (error) {
  //     console.error('Error deleting item:', error);
  //   }
  // };

  const handleDelete = async () => {
    try {
      const bearerToken = localStorage.getItem("bearerToken");
      //const apiUrl = 'https://saharadeskbackend.azurewebsites.net/api/Requests/Delete';
      const data = { id: itemIdToDelete };
      await post(DELETE_REQUESTS, data, bearerToken); // Use the post helper function

      toast.success("Data Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
      setTimeout(() => {}, 3000);
      setRequestsData((prevRequestsData) =>
        prevRequestsData?.filter((item) => item.id !== itemIdToDelete)
      );
      setRequestsShow(!RequestsShow);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const formattedDate = date.toISOString();

    if (endDate === null || endDate === undefined) {
      fetchDataWithStartDate(formattedDate, "");
    } else {
      fetchDataWithStartDate(formattedDate, endDate.toISOString());
    }
  };

  const fetchDataWithStartDate = (from, to) => {

  };

  const handleEndDateChange = (date) => {
    setEndDate(date);

    const formattedDate = date.toISOString();
    if (startDate === null || startDate === undefined) {
      fetchDataWithEndDate("", formattedDate);
    } else {
      fetchDataWithEndDate(startDate.toISOString(), formattedDate);
    }
  };

  const fetchDataWithEndDate = (from, to) => {
   
  };

  const debouncedSearchFunction = debounce((searchValue) => {
    setSearch(searchValue);
  }, 500);

  useEffect(() => {
    debouncedSearchFunction(debouncedSearch);
    return () => {
      debouncedSearchFunction.cancel();
    };
  }, [debouncedSearch]);

  const nextPageCheck = allResponses?.hasNextPage;
  const prevPageCheck = allResponses?.hasPreviousPage;

  const [searchTermLocation, setSearchTermLocation] = useState("");
  const [searchTermSubmitedBy, setSearchTermSubmitedBy] = useState("");

  // Filters
  const filteredLocations = shortListLocations?.filter((location) =>
    location.locationName.toLowerCase().includes(searchTermLocation.toLowerCase())
  );

  const filteredSubmittedBy = submittedBy?.filter((item) =>
    item.name.toLowerCase().includes(searchTermSubmitedBy.toLowerCase())
  );

  const handleSearchChangeLocation = (e) => {
    setSearchTermLocation(e.target.value);
  };

  const handleSearchChangeSubmitedBy = (e) => {
    setSearchTermSubmitedBy(e.target.value);
  };

  return (
    <>
      <div className="selection-grope">
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Location</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {locationId === null
                ? "All"
                : shortListLocations?.find(
                    (location) => location.id === locationId
                  ).locationName}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <div
              className="dropdown-search"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "#c5c7cd",
                position: "relative",
                alignItems: "center",
                borderRadius: "5px",
                border: "0.5px solid #f1efef",
                background: "#fff",
                marginBottom: "10px",
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              <SearchIcon />
              <input
                type="text"
                placeholder="Search"
                style={{ border: "none", outline: "none" }}
                className="dropDownSearch"
                value={searchTermLocation}
                onChange={handleSearchChangeLocation}
              />
            </div>
            <li
              onClick={() => {
                setLocationId(null);
                setMe(1);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content  ">
              {filteredLocations?.map((location, i) => (
                <li
                  style={{ padding: "6px 0px", fontSize: "14px" }}
                  key={i}
                  onClick={() => handleLocationClick(location.id)}
                >
                  {location.locationName}
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date From:</div>
            <div className="format-date">
              <DatePicker
                maxDate={endDate}
                selected={startDate}
                onChange={(date) => handleStartDateChange(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>

        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date To:</div>
            <div className="format-date">
              <DatePicker
                minDate={startDate}
                selected={endDate}
                onChange={(date) => handleEndDateChange(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>

        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Submitted By</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {submittedById === null
                ? "All"
                : submittedBy?.find((item) => item.id === submittedById)
                    .name}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <div
              className="dropdown-search"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "#c5c7cd",
                position: "relative",
                alignItems: "center",
                borderRadius: "5px",
                border: "0.5px solid #f1efef",
                background: "#fff",
                marginBottom: "10px",
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              <SearchIcon />
              <input
                type="text"
                placeholder="Search"
                style={{ border: "none", outline: "none" }}
                className="dropDownSearch"
                value={searchTermSubmitedBy}
                onChange={handleSearchChangeSubmitedBy}
              />
            </div>
            <li
              onClick={() => {
                setSubmittedById(null);
                setMe(!me);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            {filteredSubmittedBy?.map((submitted, i) => (
              <li
                style={{ padding: "6px 0px", fontSize: "14px" }}
                key={i}
                onClick={() => handleSubmittedClick(submitted.id)}
              >
                {submitted.name}
              </li>
            ))}
          </ul>
        </div>

        <form
          action=""
          className="search__box"
          style={{ height: "35px" }}
          onSubmit={(e) => {
            e.preventDefault();
            setDebouncedSearch(search);
          }}
        >
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            // onChange={(e) => setDebouncedSearch(e.target.value)}
            type="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setDebouncedSearch(search);
              }
            }}
          />
        </form>
      </div>
      <div className="inspection-tables-content">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th
                  className={
                    sortBy === "requestRef"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("requestRef")}
                >
                  <span>Ref</span>
                </th>
                <th
                  className={
                    sortBy === "assetName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("assetName")}
                >
                  <span>Asset</span>
                </th>
                <th
                  className={
                    sortBy === "locationName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("locationName")}
                >
                  <span>Location</span>
                </th>
                <th>Fault</th>
                <th
                  className={
                    sortBy === "submittedBy"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("submittedBy")}
                >
                  <span>Submitted By</span>
                </th>
                <th
                  className={
                    sortBy === "submittedDate"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("submittedDate")}
                >
                  <span>Date Submitted</span>
                </th>
                <th className="table-th"></th>
              </tr>
            </thead>
            {isLoadingNew ? (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    <Spinner animation="border" style={{ color: "#D57D2A" }} />
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {allResponses?.data?.length === 0 && (
                  <tbody>
                    <tr>
                      <td colSpan="9" className="text-center">
                        <div className="text-center">No data found</div>
                      </td>
                    </tr>
                  </tbody>
                )}
                <tbody>
                  {allResponses?.data?.map((item, i) => (
                    <tr key={i}>
                      <td className="table-ellipsis">{item.requestRef}</td>
                      <td className="table-ellipsis">{item.assetName}</td>
                      <td className="table-ellipsis">{item.locationName}</td>
                      <td className="table-ellipsis">{item.faultName}</td>
                      <td className="table-ellipsis">{item.submittedBy}</td>
                      <td className="table-ellipsis">
                        {new Date(item.submittedDate).toLocaleDateString(
                          "en-GB"
                        )}
                      </td>
                      <td>
                        <div className="table-last-btn">
                          <div className="dropdown table-edit-dropdown">
                            <button className="" data-bs-toggle="dropdown">
                              <HiDotsVertical />
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={`/requests/view-request/${item.id}`}
                                >
                                  <ViewIcon2 /> View
                                </Link>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    setRequestsShow(true);
                                    setItemIdToDelete(item.id);
                                  }}
                                >
                                  <DelateIcon2 /> Delete
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </table>
        </div>

        <div className="table-pagination pagination-center">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {RequestsPerPage} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => setRequestsPerPage(5)}>5</li>
                <li onClick={() => setRequestsPerPage(10)}>10</li>
                <li onClick={() => setRequestsPerPage(15)}>15</li>
                <li onClick={() => setRequestsPerPage(20)}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">
              {currentPage} of {allResponses?.totalPages}
            </div>
            <div className="arrow-btn">
              <button
                onClick={() =>
                  currentPage !== 1 && handlePageChange(currentPage - 1)
                }
              >
                {prevPageCheck && <ArrowLeft />}
              </button>
              <button
                onClick={() => {
                  handlePageChange(currentPage + 1);
                }}
              >
                {nextPageCheck && <ArrowRight />}
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={RequestsShow}
        onHide={() => setRequestsShow(false)}
        onDelete={handleDelete}
      />
    </>
  );
};

export default TabNewRequests;
