import React, { useState, useContext, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import EditIcon2 from "../../../Assets/Icons/EditIcon2";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import EyesIcon from "../../../Assets/Icons/EyesIcon";
import DownloadIcon from "../../../Assets/Icons/DownloadIcon";
import AddFileModal from "../Modal/AddFileModal";
import SummaryLabourCostsModal from "../Modal/SummaryLabourCostsModal";
import SummaryEditLabourCostsModal from "../Modal/SummaryEditLabourCostsModal";
import DeleteModal from "../Modal/DeleteModal";
import SummaryAddOtherCostsModal from "../Modal/SummaryAddOtherCostsModal";
import SummaryEditOtherCostsModal from "../Modal/SummaryEditOtherCostsModal";
import ViewImage from "../Modal/ViewImage";
import ViewVideo from "../Modal/ViewVideo";
import ViewDocument from "../Modal/ViewDocument";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { useLocation } from "react-router-dom";
import { post } from "../../../Services/ApiHelper";
import {
  DELETE_TICKET_FILE,
  DELETE_TICKET_LABOUR_COST,
  DELETE_TICKET_OTHER_COST,
  DELETE_TICKET_PART_COST,
} from "../../../utls/constants";
import { toast } from "react-toastify";

const ViewSummary = () => {
  const { ticketSummary, setTicketSummary, isLoading, setIsLoading } =
    useContext(WorkOrderContext);
  const [eventKey, setEventKey] = React.useState("");
  const [addFileShow, setAddFileShow] = useState(false);
  const [labourCosts, setLabourCosts] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [otherModalShow, setOtherModalShow] = useState(false);
  const [editModalData, setEditModalData] = useState({});
  const [editOtherModalData, setOtherModalData] = useState({});
  const [editOtherShow, setEditOtherShow] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const CompanyDetails = JSON.parse(localStorage.getItem("company"));
  const [imageShow, setImageShow] = useState({
    isShow: false,
    image: "",
    file_name: "",
    isFileLoading: false,
  });
  const [videoShow, setVideoShow] = useState({
    isShow: false,
    video: "",
    file_name: "",
  });
  const [documentShow, setDocumentShow] = useState({
    isShow: false,
    document: "",
    file_name: "",
  });
  //get url by using useLocation
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}`
    ) {
      setEventKey("0");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/assets`
    ) {
      setEventKey("1");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/costings`
    ) {
      setEventKey("2");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/job-cards`
    ) {
      setEventKey("3");
    }
  }, [location, ticketSummary]);

  const fileData = ticketSummary?.workOrderSummary?.ticketFileList;
  const labourData = ticketSummary?.workOrderSummary?.ticketLabourList;
  const otherData = ticketSummary?.workOrderSummary?.ticketOtherCostList;

  //function to download files
  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileUrl.split("/").pop()); // Extract file name from URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [fileDelete, setFileDelete] = useState(false);
  const [labourDelete, setLabourDelete] = useState(false);
  const [others, setOthers] = useState(false);

  const handleDelete = async () => {
    setIsLoading(false);
    try {
      const bearerToken = localStorage.getItem("bearerToken");

      const endPoint = fileDelete
        ? DELETE_TICKET_FILE
        : labourDelete
        ? DELETE_TICKET_LABOUR_COST
        : others
        ? DELETE_TICKET_PART_COST
        : "";

      const data = { id: itemIdToDelete };
      await post(endPoint, data, bearerToken);

      if (fileDelete) {
        setTicketSummary((prevState) => ({
          ...prevState,
          workOrderSummary: {
            ...prevState.workOrderSummary,
            ticketFileList: prevState.workOrderSummary.ticketFileList.filter(
              (item) => item.id !== itemIdToDelete
            ),
          },
        }));
      } else if (labourDelete) {
        setTicketSummary((prevState) => ({
          ...prevState,
          workOrderSummary: {
            ...prevState.workOrderSummary,
            ticketLabourList:
              prevState.workOrderSummary.ticketLabourList.filter(
                (item) => item.id !== itemIdToDelete
              ),
          },
        }));
      } else if (others) {
        setTicketSummary((prevState) => ({
          ...prevState,
          workOrderSummary: {
            ...prevState.workOrderSummary,
            ticketOtherCostList:
              prevState.workOrderSummary.ticketOtherCostList.filter(
                (item) => item.id !== itemIdToDelete
              ),
          },
        }));
      }

      toast.success("Data Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsLoading(true);

      setTimeout(() => {
        setDelateModalShow(false);
        setIsLoading(false);
      }, 3000);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("An error occured", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  return (
    eventKey && (
      <>
        <div className="order-request washroom-right-content responses-cont">
          <div className="washroom-title">
            <div className="fs-13 fw-medium">WORK ORDER SUMMARY</div>
            <div className="fs-13 fw-bold">
              Status:{" "}
              <button className="primary-text fw-bold">
                {ticketSummary?.workOrderSummary?.currentTicketActivity}
              </button>
            </div>
          </div>
          <div className="order-request mt-4 p-0">
            <Accordion defaultActiveKey="0">
              {/* FILES */}
              <Accordion.Item eventKey="0" className="request-box">
                <Accordion.Header className="request-box-title">
                  <div className="fs-13 fw-medium">FILES</div>
                </Accordion.Header>
                <Accordion.Body className="request-box-body p-0">
                  <div className="fs-14 fw-medium black-38">Files uploaded</div>
                  {fileData?.map((item) => (
                    <div className="upload-details" key={item.id}>
                      <div>
                        <div className="fs-14" style={{ color: "#1F2024" }}>
                          {item.fileName}
                        </div>
                        <div className="fs-13 gray-c">
                          Added: {item.createdDate}{" "}
                        </div>
                      </div>
                      <div className="upload-icons">
                        <button
                          onClick={() => {
                            const url = item.url;
                            const ext = url
                              .substring(url.lastIndexOf(".") + 1)
                              .toLowerCase(); // Normalize extension to lowercase

                            if (["jpg", "jpeg", "png"].includes(ext)) {
                              setImageShow({
                                isShow: true,
                                image: item.url,
                                file_name: item.fileName,
                              });
                            } else if (ext === "pdf") {
                              setDocumentShow({
                                isShow: true,
                                document: item.url,
                                file_name: item.fileName,
                              });
                            } else if (["mp4", "avi", "3gp"].includes(ext)) {
                              setVideoShow({
                                isShow: true,
                                video: item.url,
                                file_name: item.fileName,
                              });
                            }
                          }}
                        >
                          <EyesIcon />
                        </button>

                        <button onClick={() => handleDownload(item.url)}>
                          <DownloadIcon />
                        </button>
                        <button
                          onClick={() => {
                            setDelateModalShow(true);
                            setFileDelete(true);
                            setItemIdToDelete(item.id);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className="text-end">
                    <button
                      className="add-button my-3 "
                      onClick={() => setAddFileShow(true)}
                    >
                      Add File
                    </button>
                    <AddFileModal
                      show={addFileShow}
                      onHide={() => setAddFileShow(false)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {/* LABOUR COSTS */}
              <Accordion.Item eventKey="1" className="request-box">
                <Accordion.Header className="request-box-title">
                  <div className="fs-13 fw-medium">LABOUR COSTS</div>
                </Accordion.Header>
                <Accordion.Body className="request-box-body p-0">
                  <div
                    className="table-responsive-sm request_table"
                    style={{ maxWidth: "100%" }}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Staff Name</th>
                          <th scope="col">Team</th>
                          <th scope="col">Hours</th>
                          <th scope="col">Rate per Hour</th>
                          <th scope="col">Total Cost</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {labourData.map((item) => (
                          <tr key={item.id}>
                            <td>{item.userName}</td>
                            <td>{item.teamName}</td>
                            <td>{item.manHoursUsed}</td>
                            <td>
                              {CompanyDetails?.currency +
                                " " +
                                item.perHourRate?.toLocaleString()}
                            </td>
                            <td>
                              {CompanyDetails?.currency +
                                " " +
                                (
                                  item.manHoursUsed * item.perHourRate
                                )?.toLocaleString()}
                            </td>
                            <td>
                              <div className="button-con">
                                <button
                                  onClick={() => {
                                    setEditModalData(item);
                                    setEditModalShow(true);
                                  }}
                                >
                                  <EditIcon2 />
                                </button>

                                <button
                                  onClick={() => {
                                    setDelateModalShow(true);
                                    setLabourDelete(true);
                                    setItemIdToDelete(item.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <SummaryEditLabourCostsModal
                    show={editModalShow}
                    data={editModalData}
                    onHide={() => setEditModalShow(false)}
                  />

                  <div className="text-end">
                    <button
                      className="add-button my-3"
                      onClick={() => setLabourCosts(true)}
                    >
                      Add Labour Costs
                    </button>
                    <SummaryLabourCostsModal
                      show={labourCosts}
                      onHide={() => setLabourCosts(false)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              {/* OTHER COSTS */}
              <Accordion.Item eventKey="2" className="request-box">
                <Accordion.Header className="request-box-title">
                  <div className="fs-13 fw-medium">OTHER COSTS</div>
                </Accordion.Header>
                <Accordion.Body className="request-box-body">
                  <div
                    className="table-responsive-sm request_table"
                    style={{ maxWidth: "100%" }}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Category</th>
                          <th scope="col">Description</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Unit Cost</th>
                          <th scope="col">Total Cost</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {otherData.map((item) => (
                          <tr key={item.id}>
                            <td>{item.costItemTypeName}</td>
                            <td>{item.description}</td>
                            <td>{item.quantity}</td>
                            <td>
                              {CompanyDetails?.currency +
                                " " +
                                item.unitCost?.toLocaleString()}
                            </td>
                            <td>
                              {CompanyDetails?.currency +
                                " " +
                                (
                                  item.quantity * item.unitCost
                                )?.toLocaleString()}
                            </td>
                            <td>
                              <div className="button-con">
                                <button
                                  onClick={() => {
                                    setOtherModalData(item);
                                    setEditOtherShow(true);
                                  }}
                                >
                                  <EditIcon2 />
                                </button>

                                <button
                                  onClick={() => {
                                    setDelateModalShow(true);
                                    setOthers(true);
                                    setItemIdToDelete(item.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <SummaryEditOtherCostsModal
                    show={editOtherShow}
                    data={editOtherModalData}
                    onHide={() => setEditOtherShow(false)}
                  />
                  <div className="text-end">
                    <button
                      onClick={() => setOtherModalShow(true)}
                      className="add-button my-3"
                    >
                      Add Other Costs
                    </button>
                    <SummaryAddOtherCostsModal
                      show={otherModalShow}
                      onHide={() => setOtherModalShow(false)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        {/* Modal function */}
        <ViewImage show={imageShow} onHide={() => setImageShow(false)} />
        <ViewVideo show={videoShow} onHide={() => setVideoShow(false)} />
        <ViewDocument
          show={documentShow}
          onHide={() => setDocumentShow(false)}
        />

        <DeleteModal
          show={delateModalShow}
          onHide={() => setDelateModalShow(false)}
          onDelete={handleDelete}
        />
      </>
    )
  );
};

export default ViewSummary;
