import React, { useMemo } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  Aggregate,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateDirective,
  AggregatesDirective,
  Page,
} from "@syncfusion/ej2-react-grids";
import { Spinner } from "react-bootstrap";
import { format } from "date-fns";

const PMCMCategoryGrid = ({
  workOrder,
  isLoading,
  filters,
  categories,
  categoryOfWorkIds,
}) => {
  const workOrderData = workOrder?.data || [];

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };

  const getCategoryNames = () => {
    return categoryOfWorkIds.map(
      (id) =>
        categories.find((category) => category.id === id)?.categoryOfWorkName ??
        ""
    );
  };

  const formattedFilters = useMemo(() => {
    if (!filters) return null;

    return {
      startDate: formatDate(filters["filter.StartDate"]),
      endDate: formatDate(filters["filter.EndDate"]),
      calendarGrouping: filters["filter.CalendarGrouping"],
      categoryOfWork: getCategoryNames(
        filters["filter.CategoryOfWorkIds"]
      ).join(", "),
    };
  }, [filters]);

  let grid;

  const headerTemplate = () => {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <h2>PM CM Performance Report</h2>
          <div className="filters">
            <p>Work Category: {filters?.categoryOfWork ?? "All"}</p>
            <p>Contractor: {filters?.contractor ?? "All"}</p>
            <p>Station: {filters?.station ?? "All"}</p>
            <p>Status: {filters?.status ?? "All"}</p>
            <p>Start Date: {filters?.startDate ?? ""}</p>
            <p>End Date: {filters?.endDate ?? ""}</p>
          </div>
        </div>
      </div>
    );
  };

  const footerTemplate = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "rgba(88, 69, 57, 0.87)",
          color: "white",
          padding: "10px",
        }}
      >
        <div>Total:</div>
        <div>{props.completedPMSum}</div>
        <div>{props.onHoldPMSum}</div>
        <div>{props.completedCMSum}</div>
        <div>{props.onHoldCMSum}</div>
      </div>
    );
  };

  return (
    <div>
      {filters && isLoading ? (
      <div style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
        <Spinner animation="border" />
      </div>
      ) : (
        <div id="pm-cm-category">
          <GridComponent
            dataSource={workOrderData}
            ref={(g) => (grid = g)}
            // allowPaging={true}
            pageSettings={{
              pageSize: workOrder?.pageSize || 10,
              pageCount: workOrder?.totalPages || 1,
              currentPage: workOrder?.currentPage || 1,
              enableQueryString: true,
            }}
            allowTextWrap={true}
          >
            <ColumnDirective
              template={headerTemplate}
              headerText="Report Info"
              width="800"
              textAlign="Center"
            />
            <h3>PM vs CM by Category of Work Report</h3>
            <div
              className=""
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gridColumnGap: "20px",
                gridRowGap: "4px",
                fontSize: "14px",
                fontWeight: 400,
                marginBottom: "10px",
                width: "60%",
              }}
            >
              <p style={{ color: "gray" }}>Start Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.startDate || ""}</span> </p>
              {/* <p style={{ color: "gray" }}>Category of Work: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.categoryOfWork || "All"}</span></p> */}
              <p style={{ color: "gray" }}>End Date: <span style={{fontWeight: "bold", color: "black"}}>{formattedFilters?.endDate || ""}</span></p>
            </div>
            <ColumnsDirective>
              <ColumnDirective
                field="categoryOfWorkName"
                headerText="Category of Work"
                width="150"
                  textAlign="Left"
                  headerTextAlign="Left"
              />
              <ColumnDirective
                field="completedPM"
                headerText="Completed PM"
                width="150"
                textAlign="Center"
                headerTextAlign="Center"
              />
              <ColumnDirective
                field="onHoldPM"
                headerText="On Hold PM"
                width="150"
                textAlign="Center"
                headerTextAlign="Center"
              />
              <ColumnDirective
                field="completedCM"
                headerText="Completed CM"
                width="150"
                textAlign="Center"
                headerTextAlign="Center"
              />
              <ColumnDirective
                field="onHoldCM"
                headerText="On Hold CM"
                width="150"
                textAlign="Center"
                headerTextAlign="Center"
              />
            </ColumnsDirective>
            <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field="completedPM"
                    type="Sum"
                    format="N0"
                    footerTemplate={(props) => (
                      <div style={{ color: "black", padding: "5px" }}>
                        {props.Sum}
                      </div>
                    )}
                  />
                  <AggregateColumnDirective
                    field="onHoldPM"
                    type="Sum"
                    format="N0"
                  />
                  <AggregateColumnDirective
                    field="completedCM"
                    type="Sum"
                    format="N0"
                  />
                  <AggregateColumnDirective
                    field="onHoldCM"
                    type="Sum"
                    format="N0"
                  />
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
            <Inject
              services={[Toolbar, ExcelExport, PdfExport, Aggregate, Page]}
            />
          </GridComponent>
        </div>
      )}
    </div>
  );
};

export default PMCMCategoryGrid;
