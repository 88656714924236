/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import EditIcon2 from "../../Assets/Icons/EditIcon2";
import DeleteIcon from "../../Assets/Icons/DeleteIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import EditLocationGroupingModal from "../WorkOrders/Modal/EditLocationGroupingModal";
import DeleteModal from "../WorkOrders/Modal/DeleteModal";
import usefetchData from "../../hooks/useFetchData";
import qs from 'qs';
import useMutateData from "../../hooks/useMutateData";
import {toast} from "react-toastify";

const LocationGrouppingTab = () => {
  const [editModlaShow, setEditModlaShow] = useState(false);
  const [RequestShow, setRequestShow] = useState(false); //for modal
  const [searchText, setSearchText] = useState(""); //for search
  const [filters, setFilters]=useState({})
  const [locationGroupingId, setLocationGroupingId]=useState(null)
  const [selectValue, setSelectValue] = useState({
    location: "Location",
    assetCategory: "",
    make: "",
    condition: "",
    status: "",
  });
  const[locationGroup,setLocationGroup]=useState({})
  const { data, isLoading, isError } = usefetchData(
    ["locationsgrouping-data",searchText],
    `/Locations/GetAllRegionsWithSearch`,
    {
      params: {
        search: searchText,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    },
    "Failed to get locationsgroupings-data",
    true,
    true
  );
  
  
  // //filter the groupings based on the search text
  // const filteredGroupings = groupings.filter((item) =>
  //   item.title.toLowerCase().includes(searchText.toLowerCase())
  // );
// delete locationGroup mutation
const { mutate, isLoading: isDeletingLocationGroup} = useMutateData({
  url: `/Locations/DeleteLocationGroup`,
  method: "POST",
  onSuccessfullMutation: (data) => {
    if (data?.data?.succeeded === true) {
      toast.success(data?.data?.messages[0], {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setRequestShow(false); // Hide delete modal
    } else {
      toast.error(data?.data?.messages[0], {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  },
  successMessage: `Successfully recalled costing`,
  errorMessage: "Work order creation failed",
  queryKeysToInvalidate: [["locationsgrouping-data",searchText]],
});

const onDelete = async () => {
  const requestBody = {
    id:locationGroupingId,
  };
  mutate(requestBody);
};
  return (
    <>
      <div className="d-flex gap-3 align-items-center justify-content-between my-4">
        <form
          action=""
          className="search__box"
          style={{ maxWidth: "150px", marginLeft: "auto" }}
        >
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Search"
          />
        </form>
      </div>
      <div className="grouping-content">
        {data?.map((item) => (
          <div className="groping-item " key={item}>
            <div className="groping-title d-flex align-items-center justify-content-between">
              <div className="fs-18 fw-bold" style={{color: "#091E42"}}>{item.regionName}</div>
              <div className="dropdown select-dropdown">
              <button
                  className="select-title border-0 pe-0 d-flex align-content-end justify-content-end"
                  data-bs-toggle="dropdown"
                >
                  <span className="">
                    <BsThreeDotsVertical/>
                  </span>
                </button>
                <ul className="dropdown-menu dropdown-menu-end select-menu">
                  <li onClick={() => {
                    setLocationGroup(item)
                    setEditModlaShow(true)}}>
                    <EditIcon2 /> Edit
                  </li>
                  <li onClick={() =>{ 
                    setLocationGroupingId(item.id)
                    setRequestShow(true)
                    }
                    }>
                     
                    <DeleteIcon /> Delete
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="fs-14 fw-medium lh-sm pt-3"
              style={{ color: "#667085" }}
            >
              {item.regionDescription}
            </div>
          </div>
        ))}
      </div>
      <EditLocationGroupingModal
        show={editModlaShow}
        onHide={() => setEditModlaShow(false)}
        data={locationGroup}
        searchText={searchText}
      />
      <DeleteModal 
      show={RequestShow} onHide={() => setRequestShow(false)} onDelete={onDelete}  isDeleting={isDeletingLocationGroup} />
    </>
  );
};

export default LocationGrouppingTab;


