import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { PiWarningCircleFill } from "react-icons/pi";
import { Spinner } from "react-bootstrap";
import EditRequestModal from "./EditRequestModal";

const DashboardInfoModal = ({ show, toggleModal }) => { 

  return (
    <>
    <Modal
      show={show}
      onHide={toggleModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div>
            <span className="fs-16 fw-bold">Section Info</span>
            <span className="fs-14 ps-3">Extra information regarding the section</span>
          </div>
          <div
            className="delate-warning"
            style={{
              background: "#F1EFEF",
              height: "267px",
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
              marginTop: "30px",
              borderRadius: "5px",
            }}
          >
            <div>
              <PiWarningCircleFill
                style={{ color: "#D57D2A", fontSize: "70px" }}
              />
              <div
                className="fs-20 fw-medium"
                style={{ lineHeight: "1.4", marginTop: "10px" }}
              >
                This modal contains extra information on the dashboard. 
              </div>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            
            <button className="delate-btn" onClick={toggleModal}>
            Okay
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </>
  );
};

export default DashboardInfoModal;
