/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../Assets/Icons/ViewIcon2";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../Assets/Icons/DropdownIcon";
import getUniqueProperty from "../../utls/getUniqueProprty";
import filterViaProperty from "../../utls/filterViaProperty";
import searchAllProperty from "../../utls/searchAllProperty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { get, post } from "../../Services/ApiHelper";
import {
  DELETE_WORKORDER,
  GET_CLOSED_WORKORDERS_BY_ASSIGNED_TO,
  GET_SIMPLE_LOCATION_LIST,
  GET_SUBMITTED_COSTS,
  GET_SUBMITTED_COSTS2,
  GET_SUBMITTED_COSTS_BY_APPROVAL_STATUS,
  GET_SUBMITTED_COSTS_BY_END_DATE,
  GET_SUBMITTED_COSTS_BY_LOCATION,
  GET_SUBMITTED_COSTS_BY_START_DATE,
  GET_SUBMITTED_COSTS_BY_TEAM_ID,
} from "../../utls/constants";
// import toast from "react-hot-toast";
import { debounce } from "lodash";
import { Spinner } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import "./WorkCosts.scss";
import { toast } from "react-toastify";
import usefetchData from "../../hooks/useFetchData";

const WorkCostsTable = () => {
  const [assetCategories, setAssetCategories] = useState(""); //Asset Select
  const [selectedLocation, setSelectedLocation] = useState(""); //Location Select
  const [selectedPriority, setSelectedPriority] = useState(""); //Priority Select
  const [selectedAssigned, setSelectedAssigned] = useState(""); //Assigned Select
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [RequestsPerPage, setRequestsPerPage] = useState(10); //for pagination
  const [search, setSearch] = useState(""); //for search
  const [RequestsShow, setRequestsShow] = useState(false); //for modal

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const [workOrderData, setWorkOrderData] = useState([]);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [allResponses, setAllResponses] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [teams, setTeams] = useState([]);
  const [statusId, setStatusId] = useState(null);
  const [assignedToId, setAssignedToId] = useState(null);
  const [ticketPriorityId, setTicketPriorityId] = useState(null);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [me, setMe] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [locationSearch, setLocationSearch] = useState("");
  const [prioritySearch, setPrioritySearch] = useState("");
  const [searchTermLocation, setSearchTermLocation] = useState("");
  const [shortListLocations, setShortListLocations] = useState([]);
  const [searchTermStatus, setSearchTermAssetCat] = useState("");
  const [searchTermTeams, setSearchTermTeams] = useState("");

  const handleSearchChangeLocation = (e) => {
    setSearchTermLocation(e.target.value);
  };

  const handleSearchChangeStatus = (e) => {
    setSearchTermAssetCat(e.target.value);
  };

  const handleSearchChangeTeams = (e) => {
    setSearchTermTeams(e.target.value);
  };

  useEffect(() => {
    get(GET_SIMPLE_LOCATION_LIST, localStorage.getItem("bearerToken"))
      .then((response) => {
        setShortListLocations(response);
      })
      .catch((err) => {});
  }, []);

  const { data: categroies } = usefetchData(
    ["categroies"],
    `/Assets/Categories`,
    {},
    "Couldn't get priority data. Please try again!",
    true
  );

  const { data: teamData } = usefetchData(
    ["team-data"],
    `/Team/GetAllTeams`,
    {},
    "Couldn't get team data. Please try again!",
    true
  );

  const { data: statusData } = usefetchData(
    ["status-data"],
    `/TicketCostings/GetAllApprovalStates`,
    {},
    "Couldn't get status data. Please try again!",
    true
  );


  const handleDelete = async () => {
    try {
      const bearerToken = localStorage.getItem("bearerToken");
      //const apiUrl = 'https://saharadeskbackend.azurewebsites.net/api/Requests/Delete';
      const data = { id: itemIdToDelete };
      await post(DELETE_WORKORDER, data, bearerToken); // Use the post helper function

      toast.success("Data Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {}, 3000);
      setWorkOrderData((prevRequestsData) =>
        prevRequestsData?.filter((item) => item.id !== itemIdToDelete)
      );
      setRequestsShow(!RequestsShow);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("An error occured", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {
        setRequestsShow(false);
      }, 3000);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty date strings

    const date = new Date(dateString);
    const day = date?.getDate().toString().padStart(2, "0");
    const month = (date?.getMonth() + 1).toString().padStart(2, "0");
    const year = date?.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  };

  const style = {
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  useEffect(() => {
    const stringedStartDate = startDate?.toISOString();
    const stringedEndDate = endDate?.toISOString();

    get(
      GET_SUBMITTED_COSTS2(
        RequestsPerPage,
        currentPage,
        debouncedSearch,
        statusId,
        stringedStartDate,
        stringedEndDate,
        teamId,
        locationId,
        sortOrder
      ),
      localStorage.getItem("bearerToken")
    ).then((response) => {
      setWorkOrderData(response["data"]);
      setIsLoading(false);
      setAllResponses(response);
    });
  }, [
    RequestsPerPage,
    currentPage,
    debouncedSearch,
    statusId,
    startDate,
    endDate,
    teamId,
    locationId,
    sortOrder,
  ]);

  const handleLocationClick = (locationId) => {
    setLocationId(locationId);
    setCurrentPage(1);
  };

  const handleTeamsClick = (teamId) => {
    setTeamId(teamId);
    setCurrentPage(1);
  };

  const handleStatusClick = (statusId) => {
    setStatusId(statusId);
    setCurrentPage(1);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setCurrentPage(1);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setCurrentPage(1);
  };

  const debouncedSearchFunction = debounce((searchValue) => {
    setSearch(searchValue);
  }, 500);

  useEffect(() => {
    debouncedSearchFunction(debouncedSearch);
    return () => {
      debouncedSearchFunction.cancel();
    };
  }, [debouncedSearch]);

  const nextPageCheck = allResponses?.hasNextPage;
  const prevPageCheck = allResponses?.hasPreviousPage;

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedRequestsData = workOrderData?.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA?.localeCompare(valueB)
        : valueB?.localeCompare(valueA);
    }
    return 0;
  });

  //get unique asset category & filter by asset category
  const assetCategory = getUniqueProperty(
    workOrderData,
    "approvalStateId",
    "approvalLevelStateName"
  );
  const locations = getUniqueProperty(
    workOrderData,
    "locationId",
    "locationName"
  );
  const priority = getUniqueProperty(
    workOrderData,
    "costingTeamId",
    "costingTeamUserName"
  );
  const assignedTo = getUniqueProperty(
    workOrderData,
    "currentAssignedTeamId",
    "currentAssignedTeamUserName"
  );

  const filterByAsset = filterViaProperty(
    sortedRequestsData,
    assetCategories,
    "category"
  );

  //get request locations & filter by location

  const filterByLocation = filterViaProperty(
    filterByAsset,
    selectedLocation,
    "location"
  );

  //get priority & filter by priority

  const filterByPriority = filterViaProperty(
    filterByLocation,
    selectedPriority,
    "priority"
  );

  //filter via start date if date is not null
  const filteredByStartDate = startDate
    ? filterByPriority?.filter((item) => {
        const date = new Date(item?.closedDate);
        return date?.getTime() >= startDate?.getTime();
      })
    : filterByPriority;

  //filter via end date if date is not null
  const filteredByEndDate = endDate
    ? filteredByStartDate?.filter((item) => {
        const date = new Date(item?.closedDate);
        return date?.getTime() <= endDate?.getTime();
      })
    : filteredByStartDate;

  // get unique Assigned by & filter by Assigned by

  const filteredByAssigned = filterViaProperty(
    filteredByEndDate,
    selectedAssigned,
    "assignTo"
  );

  // filter via search
  const filteredBySearch = searchAllProperty(filteredByAssigned, search);

  //for pagination
  const indexOfLastRequests = currentPage * RequestsPerPage;
  const indexOfFirstRequests = indexOfLastRequests - RequestsPerPage;
  const currentRequests = filteredBySearch?.slice(
    indexOfFirstRequests,
    indexOfLastRequests
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredBySearch?.length / RequestsPerPage);

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
 

  const filteredStatus = statusData?.filter((item) =>
    item?.approvalStatesName
      ?.toLowerCase()
      .includes(searchTermStatus.toLowerCase())
  );

  const filteredLocations = shortListLocations?.filter((location) =>
    location?.locationName
      ?.toLowerCase()
      .includes(searchTermLocation.toLowerCase())
  );

  const filteredTeams = teamData?.filter((item) =>
    item?.teamName?.toLowerCase().includes(searchTermTeams.toLowerCase())
  );

  const handleSearch = () => {
    setDebouncedSearch(search);
    setCurrentPage(1);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <div className="selection-grope">
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Status</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {statusId === null
                ? "All"
                : statusData?.find((item) => item.id === statusId)
                    ?.approvalStatesName}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <div
              className="dropdown-search"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "#c5c7cd",
                position: "relative",
                alignItems: "center",
                borderRadius: "5px",
                border: "0.5px solid #f1efef",
                background: "#fff",
                marginBottom: "10px",
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              <SearchIcon />
              <input
                type="text"
                placeholder="Search"
                style={{ border: "none", outline: "none" }}
                className="dropDownSearch"
                value={searchTermStatus}
                onChange={handleSearchChangeStatus}
              />
            </div>
            <li
              onClick={() => {
                setStatusId(null);
                setMe(!me);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {filteredStatus?.map((asset, i) => (
                <li
                  key={i}
                  onClick={() => handleStatusClick(asset.id)}
                  style={{ padding: "6px 0px", fontSize: "14px" }}
                >
                  {asset.approvalStatesName}
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Location</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {locationId === null
                ? "All"
                : shortListLocations?.find(
                    (location) => location.id === locationId
                  )?.locationName}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <div
              className="dropdown-search"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "#c5c7cd",
                position: "relative",
                alignItems: "center",
                borderRadius: "5px",
                border: "0.5px solid #f1efef",
                background: "#fff",
                marginBottom: "10px",
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              <SearchIcon />
              <input
                type="text"
                placeholder="Search"
                style={{ border: "none", outline: "none" }}
                className="dropDownSearch"
                value={searchTermLocation}
                onChange={handleSearchChangeLocation}
              />
            </div>
            <li
              onClick={() => {
                setLocationId(null);
                setMe(1);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {filteredLocations?.map((location, i) => (
                <li
                  key={i}
                  onClick={() => handleLocationClick(location.id)}
                  style={{ padding: "6px 0px", fontSize: "14px" }}
                >
                  {location.locationName}
                </li>
              ))}
            </div>
          </ul>
        </div>

        {/* Team/Contractor Dropdown */}
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Team</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {teamId === null
                ? "All"
                : teamData?.find((item) => item.id === teamId)?.teamName}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <div
              className="dropdown-search"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                color: "#c5c7cd",
                position: "relative",
                alignItems: "center",
                borderRadius: "5px",
                border: "0.5px solid #f1efef",
                background: "#fff",
                marginBottom: "10px",
                padding: "5px",
                paddingLeft: "15px",
              }}
            >
              <SearchIcon />
              <input
                type="text"
                placeholder="Search"
                style={{ border: "none", outline: "none" }}
                className="dropDownSearch"
                value={searchTermTeams}
                onChange={handleSearchChangeTeams}
              />
            </div>
            <li
              onClick={() => {
                setTeamId(null);
                setMe(!me);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {filteredTeams?.map((asset, i) => (
                <li
                  key={i}
                  onClick={() => handleTeamsClick(asset.id)}
                  style={{ padding: "6px 0px", fontSize: "14px" }}
                >
                  {asset.teamName}
                </li>
              ))}
            </div>
          </ul>
        </div>

        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date From:</div>
            <div className="format-date">
              <DatePicker
                maxDate={endDate}
                selected={startDate}
                onChange={(date) => handleStartDateChange(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>
        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date To:</div>
            <div className="format-date">
              <DatePicker
                minDate={startDate}
                selected={endDate}
                onChange={(date) => handleEndDateChange(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>
        {/* <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Assigned To</span>
            <span className="fs-14 d-flex align-items-center gap-1">
            {assignedToId === null ? "All" : assignedTo?.find(item => item.id === assignedToId)?.name}<DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li onClick={() => {
              setAssignedToId(null)
              setMe(!me)
              }}>
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {assignedTo?.map((assigned, i) => (
                <li key={i} onClick={() => handleAssignedClick(assigned.id)}>
                  {assigned.name}
                </li>
              ))}
            </div>
          </ul>
        </div> */}

        <form action="" className="search__box">
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={handleKeyPress}
            type="search"
            placeholder="Search"
          />
        </form>
      </div>
      <div className="inspection-tables-content">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th
                  className={
                    sortBy === "ticketRef"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("ticketRef")}
                >
                  <span>Ref</span>
                </th>
                <th
                  className={
                    sortBy === "ticketTitle"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("ticketTitle")}
                >
                  <span>Work</span>
                </th>
                <th
                  className={
                    sortBy === "locationName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("locationName")}
                >
                  <span>Location</span>
                </th>
                <th
                  className={
                    sortBy === "approvalLevelStateName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("approvalLevelStateName")}
                >
                  <span>Status</span>
                </th>
                <th
                  className={
                    sortBy === "approvalLevelStateName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("approvalLevelStateName")}
                >
                  <span>Tot. Amount</span>
                </th>
                <th
                  className={
                    sortBy === "currentAssignedTeamUserName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("currentAssignedTeamUserName")}
                >
                  <span>Team/Contractor</span>
                </th>
                <th
                  className={
                    sortBy === "dateSubmitted"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("dateSubmitted")}
                >
                  <span>Date Submitted</span>
                </th>
                <th className="table-th"></th>
              </tr>
            </thead>
            {isLoading && (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    <Spinner animation="border" style={{ color: "#D57D2A" }} />
                  </td>
                </tr>
              </tbody>
            )}
            {workOrderData?.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    <div className="text-center">No data found</div>
                  </td>
                </tr>
              </tbody>
            )}
            <tbody>
              {workOrderData?.map((item, i) => (
                <tr key={i}>
                  <td>{item?.ticketRef}</td>
                  <td style={style}>{item?.ticketTitle}</td>
                  <td style={style}>{item?.locationName}</td>
                  <td style={style}>{item?.approvalLevelStateName}</td>
                  <td style={style}>{item?.costingTotal}</td>
                  <td style={style}>{item?.costingTeamUserName}</td>
                  <td style={style}>{formatDate(item?.dateSubmitted)}</td>
                  <td>
                    <div className="table-last-btn d-flex align-items-center justify-content-end">
                      {item?.rejected && (
                        <span
                          style={{
                            background: "#FFBBBD",
                            color: "#F40",
                            fontSize: "12px",
                            fontWeight: "600",
                            padding: "5px 10px",
                            display: "inline-block",
                            borderRadius: "15px",
                            marginRight: "7px",
                          }}
                        >
                          {item?.rejected}
                        </span>
                      )}
                      <div className="dropdown table-edit-dropdown">
                        <button className="" data-bs-toggle="dropdown">
                          <HiDotsVertical />
                        </button>

                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/work-costs/view/${item?.ticketId}`}
                            >
                              <ViewIcon2 /> View
                            </Link>
                          </li>
                          {/*<li>
                            <Link
                              to="/work-orders/add-work-order"
                              className="dropdown-item"
                            >
                              <EditIcon2 /> Edit
                            </Link>
                    </li>*/}
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-pagination pagination-center">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {RequestsPerPage} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => setRequestsPerPage(5)}>5</li>
                <li onClick={() => setRequestsPerPage(10)}>10</li>
                <li onClick={() => setRequestsPerPage(15)}>15</li>
                <li onClick={() => setRequestsPerPage(20)}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">
              {currentPage} of {allResponses?.totalPages}
            </div>
            <div className="arrow-btn">
              <button onClick={() => handlePageChange(currentPage - 1)}>
                {prevPageCheck && <ArrowLeft />}
              </button>
              <button onClick={() => handlePageChange(currentPage + 1)}>
                {nextPageCheck && <ArrowRight />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkCostsTable;

const diagnosisQuotesData = [
  {
    ref: "1000001",
    fault: "Pump leakage",
    location: "Mavoko Station",
    status: "Invited to Quote",
    totAmount: "15,670",
    submitBy: "James Muli",
    submitDate: "01/11/2024",
  },
  {
    ref: "1000002",
    fault: "Pump overdispensing",
    location: "Mavoko Station",
    status: "Invited to Quote",
    totAmount: "450,000",
    submitBy: "Ivan Miguna",
    submitDate: "01/11/2024",
  },
  {
    ref: "1000003",
    fault: "Faulty screen",
    location: "Mavoko Station",
    status: "Quoted",
    totAmount: "23,710",
    submitBy: "Ivan Miguna",
    submitDate: "01/11/2024",
  },
  {
    ref: "1000004",
    fault: "Pump leakage",
    location: "Kisaju Station",
    status: "Quoted",
    totAmount: "34,800",
    submitBy: "Dorcas Sitati",
    submitDate: "01/15/2024",
  },
  {
    ref: "1000005",
    fault: "Pump leakage",
    location: "Kisaju Station",
    status: "Work Assigned",
    totAmount: "91,240",
    submitBy: "Dorcas Sitati",
    submitDate: "01/15/2024",
  },
  {
    ref: "1000006",
    fault: "Faulty manual meter",
    location: "Kisaju Station",
    status: "Work Not Assigned",
    totAmount: "45,000",
    submitBy: "Amos Wambuka",
    submitDate: "01/15/2024",
  },
  {
    ref: "1000007",
    fault: "Faulty submersible",
    location: "Kisaju Station",
    status: "Work Assigned",
    totAmount: "3,000",
    submitBy: "Alphonce Michuki",
    submitDate: "01/16/2024",
  },
  {
    ref: "1000008",
    fault: "Calibration overdue",
    location: "Kisaju Station",
    status: "Work Not Assigned",
    totAmount: "6,700",
    submitBy: "Alphonce Michuki",
    submitDate: "01/16/2024",
  },
  {
    ref: "1000009",
    fault: "Calibration overdue",
    location: "Kisaju Station",
    status: "Work Not Assigned",
    totAmount: "5,600",
    submitBy: "Dorcas Sitati",
    submitDate: "01/17/2024",
  },
];
