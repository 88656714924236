import React, { useState, useEffect } from "react";
import DeleteModal from "../../Modal/DeleteModal";
import DeleteIcon from "../../../../Assets/Icons/DeleteIcon";
import SubmitCostsModal from "../../Modal/SubmitCostsModal";
import ConfirmSubmitModal from "../../Modal/ConfirmSubmitModal";
import RecallCostsModal from "../../Modal/RecallCostsModal";
import AddPartsCostsModal from "../../Modal/AddPartsCostsModal";
import AddLabourCostsModal from "../../Modal/AddLabourCostsModal";
import AddOtherCostsModal from "../../Modal/AddOtherCostsModal";
import { useLocation } from "react-router-dom";
import {
  DELETE_TICKET_COSTED_ITEM,
  DELETE_TICKET_OTHER_COST,
  DELETE_TICKET_PART_COST,
} from "../../../../utls/constants";
import { toast } from "react-toastify";
import useMutateData from "../../../../hooks/useMutateData";

const ManualInput = ({ ticketSummary, ticketId }) => {
  const [eventKey, setEventKey] = React.useState("");
  const [costsShow, addCostsShow] = useState(false);
  const [labourCostsShow, setLabourCostsShow] = useState(false);
  const [otherCostsShow, setOtherCostsShow] = useState(false);
  const [partsCosting, setPartsCosting] = useState(false);
  const [labourCosting, setLabourCosting] = useState(false);
  const [otherCosting, setOtherCosting] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const CompanyDetails = JSON.parse(localStorage.getItem("company"));
  const [showNav, setShowNav] = useState(true);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [addPartsCostModalShow, setAddPartsCostModalShow] = useState(false);
  const [addLabourCostModalShow, setAddLabourCostModalShow] = useState(false);
  const [addOtherCostModalShow, setAddOtherCostModalShow] = useState(false);
  const [submitCostsModalShow, setSubmitCostsModalShow] = useState(false);
  const [confirmSubmitModalShow, setConfirmSubmitModalShow] = useState(false);
  const [recallCostsModalShow, setRecallCostsModalShow] = useState(false);
  const [showSubmitCostsContent, setShowSubmitCostsContent] = useState(true);

  const company = localStorage.getItem("company");
  const companyObj = company && JSON.parse(company);
  const currency = companyObj.currency;

  const handleSubmitCosts = () => {
    setConfirmSubmitModalShow(false);
    setShowSubmitCostsContent(false);
    setShowNav(false); // Hide the nav bar
  };

  const handleRecallCosts = () => {
    setRecallCostsModalShow(false);
    setShowSubmitCostsContent(true);
    setShowNav(true); // Show the nav bar
  };

  const handleOkayClick = () => {
    setSubmitCostsModalShow(false);
    setConfirmSubmitModalShow(true);
  };

  const handleShowRecall = () => {
    setRecallCostsModalShow(true);
  };

  const handleShowSubmit = () => {
    setConfirmSubmitModalShow(true);
  };

  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}`
    ) {
      setEventKey("0");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/assets`
    ) {
      setEventKey("1");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/costings`
    ) {
      setEventKey("2");
    } else if (
      location.pathname ===
      `/work-orders/work-view/${ticketSummary?.workOrderSummary?.ticketId}/job-cards`
    ) {
      setEventKey("3");
    }
  }, [location, ticketSummary]);

  const partsData =
    ticketSummary?.costingSummary?.workOrderCostingBasis?.costedUsedPartList;
  const labourData =
    ticketSummary?.costingSummary?.workOrderCostingBasis?.costedLabourList;

  const otherData =
    ticketSummary?.costingSummary?.workOrderCostingBasis?.costedOtherList;
  const basicGroupId =
    ticketSummary?.costingSummary?.workOrderCostingBasis?.costingBasisGroupId;
  // Function to sum up the total values in an array of items
  const getTotalSum = (dataArray) => {
    if (!dataArray) return 0;
    return dataArray.reduce((totalSum, currentItem) => {
      return totalSum + currentItem.quantity * currentItem.unitCost;
    }, 0);
  };
  // Get the total sum of each array
  const totalPartsSum = getTotalSum(partsData);
  const totalLabourSum = getTotalSum(labourData);
  const totalOtherSum = getTotalSum(otherData);
  // Get the overall total sum
  const overallTotalSum = totalPartsSum + totalLabourSum + totalOtherSum;

  const endPoint = partsCosting
    ? DELETE_TICKET_PART_COST
    : labourCosting
    ? DELETE_TICKET_PART_COST
    : otherCosting
    ? DELETE_TICKET_COSTED_ITEM
    : "";

  // submit constings
  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: `Tickets/SubmitTicketCostedItems`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setConfirmSubmitModalShow(false);
        setShowSubmitCostsContent(false);
        setShowNav(false);
        // Hide the nav bar
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setConfirmSubmitModalShow(false);
      }
    },
    successMessage: `Manual Costing submitted successfully`,
    errorMessage: "Costing submission failed",
    queryKeysToInvalidate: [["ticket-summary", ticketId]],
  });

  // recall constings
  const { mutate: recall, isLoading: isRecalling } = useMutateData({
    url: `Tickets/RecallTicketCostedItems`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      console.log("Recall mutation data", data);
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setRecallCostsModalShow(false);
        setShowSubmitCostsContent(true);
        setShowNav(true); // Show the nav bar
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [
      // ["price-list", categoryId],
      ["ticket-summary", ticketId],
    ],
  });

  const onSubmitCostings = () => {
    const requestBody = {
      costingBasisGroupId: basicGroupId,
      ticketId: ticketId,
    };
    mutate(requestBody);
  };

  const onRecallCostings = () => {
    const requestBody = {
      ticketId: ticketId,
      costingBasisGroupId: basicGroupId,
    };
    recall(requestBody);
  };

  // delete cost item
  const { mutate: deleteItem, isLoading: isDeleting } = useMutateData({
    url: endPoint,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setDeleteModalShow(false);
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setDeleteModalShow(false);
      }
      // get work order details
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["ticket-summary", ticketId]],
  });

  const handleDelete = async () => {
    const data = { id: itemIdToDelete };
    deleteItem(data);
  };

  return (
    <>
      {!ticketSummary?.costingSummary?.workOrderCostingBasis?.isSubmitted ? (
        <div className="order-request mt-4 p-0 view-costings">
          <div className="request-box">
            <div className="request-box-body py-25 px-10">
              <div className="d-flex align-items-center justify-content-between gap-3 pt-3 pb-3">
                <div className=""></div>
                <button
                  className="fs-15 fw-bold"
                  style={{ color: "#D57D2A" }}
                  onClick={handleShowSubmit}
                  disabled={
                    !ticketSummary?.costingSummary?.workOrderCostingBasis
                  }
                >
                  SUBMIT COST
                </button>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-15 fw-medium black-38">PARTS COSTING</div>
                <button
                  onClick={() => addCostsShow(true)}
                  className="add-button my-2"
                  style={{ fontSize: "15px", fontWeight: "500" }}
                >
                  Add Cost
                </button>
                <AddPartsCostsModal
                  show={costsShow}
                  onHide={() => addCostsShow(false)}
                  ticketSummary={ticketSummary}
                />
              </div>
              <div
                className="table-responsive-sm request_table"
                style={{ maxWidth: "100%" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Parts</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Unit Cost</th>
                      <th scope="col">Total Cost</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {partsData?.map((item) => (
                      <tr key={item.id}>
                        <td>{item.partName}</td>
                        <td>{item.quantity}</td>
                        <td>
                          {CompanyDetails?.currency && item.unitCost
                            ? CompanyDetails.currency +
                              " " +
                              item.unitCost.toLocaleString()
                            : "N/A"}
                        </td>
                        <td>
                          {CompanyDetails?.currency +
                            " " +
                            (item.quantity * item.unitCost).toLocaleString()}
                        </td>
                        <td>
                          <div className="button-con">
                            <button
                              onClick={() => {
                                setDeleteModalShow(true);
                                setItemIdToDelete(item.id);
                                setPartsCosting(true);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-15 fw-medium black-38">LABOUR COSTING</div>
                <button
                  onClick={() => setLabourCostsShow(true)}
                  className="add-button my-2"
                  style={{ fontSize: "15px", fontWeight: "500" }}
                >
                  Add Cost
                </button>
                <AddLabourCostsModal
                  show={labourCostsShow}
                  onHide={() => setLabourCostsShow(false)}
                  ticketSummary={ticketSummary}
                />
              </div>
              <div
                className="table-responsive-sm request_table"
                style={{ maxWidth: "100%" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Staff Name</th>
                      <th scope="col">Team</th>
                      <th scope="col">Hours</th>
                      <th scope="col">Rate per Hour</th>
                      <th scope="col">Amount</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {labourData?.map((item) => (
                      <tr key={item.id}>
                        <td>{item.userName}</td>
                        <td>{item.teamName}</td>
                        <td>{item.quantity}</td>
                        <td>
                          {CompanyDetails?.currency && item.unitCost
                            ? CompanyDetails.currency +
                              " " +
                              item.unitCost.toLocaleString()
                            : "N/A"}
                        </td>
                        <td>
                          {CompanyDetails?.currency +
                            " " +
                            (item.quantity * item.unitCost).toLocaleString()}
                        </td>
                        <td>
                          <div className="button-con">
                            <button
                              onClick={() => {
                                setDeleteModalShow(true);
                                setItemIdToDelete(item.id);
                                setLabourCosting(true);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-15 fw-medium black-38">OTHER COSTS</div>
                <button
                  onClick={() => setOtherCostsShow(true)}
                  className="add-button my-2"
                  style={{ fontSize: "15px", fontWeight: "500" }}
                >
                  Add Cost
                </button>
                <AddOtherCostsModal
                  show={otherCostsShow}
                  onHide={() => setOtherCostsShow(false)}
                  ticketSummary={ticketSummary}
                />
              </div>
              <div
                className="table-responsive-sm request_table"
                style={{ maxWidth: "100%" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Category</th>
                      <th scope="col">Description</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Unit Cost</th>
                      <th scope="col">Total Cost</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {otherData?.map((item) => (
                      <tr key={item.id}>
                        <td>{item.otherCostCategoryName}</td>
                        <td>{item.description}</td>
                        <td>{item.quantity}</td>
                        <td>
                          {CompanyDetails?.currency && item.unitCost
                            ? CompanyDetails.currency +
                              " " +
                              item.unitCost.toLocaleString()
                            : "N/A"}
                        </td>
                        <td>
                          {CompanyDetails?.currency +
                            " " +
                            (item.quantity * item.unitCost).toLocaleString()}
                        </td>
                        <td>
                          <div className="button-con">
                            <button
                              onClick={() => {
                                setDeleteModalShow(true);
                                setItemIdToDelete(item.id);
                                setOtherCosting(true);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center justify-content-between pt-2 pb-3">
                <div className="fs-20 fw-bold black-38">TOTAL COST</div>
                <div className="fs-20 fw-bold black-38">
                  {CompanyDetails?.currency +
                    " " +
                    overallTotalSum.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="recall_table">
          {/* <div
            className="fs-15 fw-medium py-4 mb-3"
            style={{
              borderBottom: "1px solid #f1efef",
            }}
          >
            Based on work costs
          </div> */}

          <div className="d-flex align-items-center justify-content-between gap-3 pt-3 pb-3">
            <div className=""></div>
            <button
              className="fs-15 fw-bold"
              style={{ color: "#D57D2A" }}
              onClick={handleShowRecall}
            >
              RECALL COST
            </button>
          </div>
          {ticketSummary?.costingSummary?.workOrderCostingBasis
            ?.costedUsedPartList?.length !== 0 && (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-15 fw-medium black-38">PARTS COSTING</div>
              </div>
              <div
                className="table-responsive-sm request_table manualinput-table"
                style={{ maxWidth: "100%" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Parts</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Unit Cost</th>
                      <th scope="col">Total Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partsData?.map((item) => (
                      <tr key={item.id}>
                        <td>{item.partName}</td>
                        <td>{item.quantity}</td>
                        <td>
                          {CompanyDetails?.currency && item.unitCost
                            ? CompanyDetails.currency +
                              " " +
                              item.unitCost.toLocaleString()
                            : "N/A"}
                        </td>
                        <td>
                          {CompanyDetails?.currency +
                            " " +
                            (item.quantity * item.unitCost).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {ticketSummary?.costingSummary?.workOrderCostingBasis
            ?.costedLabourList?.length !== 0 && (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-15 fw-medium black-38">LABOUR COSTING</div>
              </div>
              <div
                className="table-responsive-sm request_table manualinput-table"
                style={{ maxWidth: "100%" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Staff Name</th>
                      <th scope="col">Team</th>
                      <th scope="col">Hours</th>
                      <th scope="col">Rate per Hour</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {labourData?.map((item) => (
                      <tr key={item.id}>
                        <td>{item.userName}</td>
                        <td>{item.teamName}</td>
                        <td>{item.quantity}</td>
                        <td>
                          {CompanyDetails?.currency && item.unitCost
                            ? CompanyDetails.currency +
                              " " +
                              item.unitCost.toLocaleString()
                            : "N/A"}
                        </td>
                        <td>
                          {CompanyDetails?.currency +
                            " " +
                            (item.quantity * item.unitCost).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {ticketSummary?.costingSummary?.workOrderCostingBasis?.costedOtherList
            ?.length !== 0 && (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-15 fw-medium black-38">OTHER COSTS</div>
              </div>
              <div
                className="table-responsive-sm request_table manualinput-table"
                style={{ maxWidth: "100%" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Category</th>
                      <th scope="col">Description</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Unit Cost</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {otherData?.map((item) => (
                      <tr key={item.id}>
                        <td>{item.otherCostCategoryName}</td>
                        <td>{item.description}</td>
                        <td>{item.quantity}</td>
                        <td>
                          {CompanyDetails?.currency && item.unitCost
                            ? CompanyDetails.currency +
                              " " +
                              item.unitCost.toLocaleString()
                            : "N/A"}
                        </td>
                        <td>
                          {CompanyDetails?.currency +
                            " " +
                            (item.quantity * item.unitCost).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          <div className="d-flex align-items-center justify-content-between pt-2 pb-3">
            <div className="fs-20 fw-bold black-38">TOTAL COST</div>
            <div className="fs-20 fw-bold black-38">
              {CompanyDetails?.currency +
                " " +
                overallTotalSum.toLocaleString()}
            </div>
          </div>
        </div>
      )}

      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        onDelete={handleDelete}
        isDeleting={isDeleting}
      />
      <AddPartsCostsModal
        show={addPartsCostModalShow}
        onHide={() => setAddPartsCostModalShow(false)}
        ticketSummary={ticketSummary}
      />
      <AddLabourCostsModal
        show={addLabourCostModalShow}
        onHide={() => setAddLabourCostModalShow(false)}
      />
      <AddOtherCostsModal
        show={addOtherCostModalShow}
        onHide={() => setAddOtherCostModalShow(false)}
      />
      <SubmitCostsModal
        show={submitCostsModalShow}
        onHide={() => setSubmitCostsModalShow(false)}
        handleOkayClick={handleOkayClick}
      />
      <ConfirmSubmitModal
        show={confirmSubmitModalShow}
        onHide={() => setConfirmSubmitModalShow(false)}
        handleSubmitCosts={onSubmitCostings}
      />
      <RecallCostsModal
        show={recallCostsModalShow}
        onHide={() => setRecallCostsModalShow(false)}
        handleRecallCosts={onRecallCostings}
      />
    </>
  );
};

export default ManualInput;
