import React, { useState } from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import DownIcon from "../../../Assets/Icons/DownIcon";
import {toast } from "react-toastify"
import usefetchData from "../../../hooks/useFetchData";
import useMutateData from "../../../hooks/useMutateData";

const AddAssetModal = (props) => {
  const [selectCategory, setSelectCategory] = useState("");
  const [selectMake, setSelectMake] = useState("Select asset make");
  const [selectModel, setSelectModel] = useState("Select asset model");
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [showChecklistList, setShowChecklistList] = useState(false);
  const [assetName, setAssetName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [assetDescription, setAssetDescription] = useState("");
  const [isNewAsset, setIsNewAsset] = useState(false);
  const [modelsByMake, setModelsByMake] = useState([]);
  const [assetModel, setAssetModel] = useState("")
  const [selectLabourCostSearch, setSelectLabourCostSearch] = useState("");

  const {id} = useParams()

  //assign asset make,category,asset search input
  const [ADSearch,setADSearch]=useState("");
  const [ACSearch,setACSearch]=useState("");
  const [AMSearch,setAMSearch]=useState("");

    //get search input
    const [selectCategorySearch, setSelectCategorySearch] = useState("");
    const [selectMakeSearch, setSelectMakeSearch] = useState("");
    const [selectTeamSearch, setSelectTeamSearch] = useState("");
  

  const { data: AssetMakeData } = usefetchData(
    ["asset-make"],
    `Assets/GetAllAssetMakes`,
    {},
    "Error fetching AssetsMake grouping data"
  );

  const { data: AssetCategoryData } = usefetchData(
    ["asset-category"],
    `Assets/Categories`,
    {},
    "Error fetching Asset Category data"
  );

  const { data: AssetData } = usefetchData(
    ["all-assets"],
    `Assets/GetAllAssets`,
    {},
    "Error fetching AssetData data"
  );

  // Set up mutate for getting asset models by make
  const { mutate: fetchModelsByMake } = useMutateData({
    url: "/Assets/GetAssetModelByMake",
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data) {
        setModelsByMake(data.data);
      } else {
        toast.error("Error fetching asset models");
      }
    },
    errorMessage: "Error fetching asset models"
  });

  // Handle the make selection to get models by make
  const handleSelectMake = (eventKey) => {
    const selectedMake = AssetMakeData?.find(asset => asset.makeName === eventKey);
    if (selectedMake) {
      setSelectMake(eventKey);
      fetchModelsByMake({ assetMakeId: selectedMake.assetMakeId });
    }
  };


  //handle select
  const handleSelectCategory = (eventKey) => {
    const selectedCategory =  AssetCategoryData.find(asset => asset.assetCategoryName === eventKey);
    if(selectCategory){
    setSelectCategory(eventKey);
    setACSearch("");
  }
  };
  //handle select
  const handleSelectName = (eventKey) => {
    const selectedAssetName =  AssetMakeData.find(asset => asset.makeName === eventKey);
    if(selectedAssetName){
    setAssetName(eventKey);
    setADSearch("");
  }
  };

  
  const handleSelectTeam = (eventKey) => {
    setSelectCategory(eventKey);
    setSelectTeamSearch("");
  };

  // const handleCheckboxChange = (asset) => {
  //   setSelectedAssets((prevSelectedAssets) => {
  //     if (prevSelectedAssets.includes(asset)) {
  //       return prevSelectedAssets.filter(
  //         (selectedAsset) => selectedAsset !== asset
  //       );
  //     } else {
  //       return [...prevSelectedAssets, asset];
  //     }
  //   });
  // };

  // Filter data for dropdown search
  const filteredACDData = AssetCategoryData?.filter(item => item.assetCategoryName?.toLowerCase());
  const filteredAssetData = AssetData?.data?.filter(item => item.assetName?.toLowerCase());
  //filter assign category data
  const filteredAMData = AssetMakeData && AssetMakeData?.filter((item) => {
    return item.makeName?.toLowerCase().includes(AMSearch.toLowerCase());
  });


  const { mutate, isLoading: isSubmitting } = useMutateData({
    url: isNewAsset ? "/Assets/AddAsset" : "/Locations/AddExistingAssetsToLocation",
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded) {
        toast.success('Asset operation successful', { position: 'top-right', autoClose: 3000 });
        props.onHide();
      } else {
        toast.error('Operation failed', { position: 'top-right', autoClose: 3000 });
      }
    }
  });


  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = isNewAsset ? {
      assetName,
      assetDescription,
      assetCategoryId: selectCategory.assetCategoryId,
      assetModelId: selectModel.assetModelId,
      serialNumber,
    } : {
      locationId: parseInt(id),
      assetIds: selectedAssets.map(asset => asset.assetId),
    };
    mutate(payload);
  };

  const handleCheckboxChange = (asset) => {
    setSelectedAssets(prev => {
      const isAlreadySelected = prev.some(selectedAsset => selectedAsset.assetId === asset.assetId);
      
      if (isAlreadySelected) {
        // Remove asset if already selected
        return prev.filter(selectedAsset => selectedAsset.assetId !== asset.assetId);
      } else {
        // Add asset if not already selected
        return [...prev, asset];
      }
    });
  };
  

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal modal-size-780 add-asset-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-md-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Asset</span>
            <div className="fs-14 pt-2 pt-md-0" style={{ color: "#72777A" }}>
              Add an asset to this location
            </div>
          </div>
          <div className="fs-16 fw-semibold" style={{ paddingTop: "30px" }}>
            Mode
          </div>
          <Tab.Container defaultActiveKey="first" onSelect={(key) => setIsNewAsset(key === "second")}>
            <Nav variant="pills" className="existing-tab-nav row">
              <Nav.Item className="col-md-6">
                <Nav.Link eventKey="first">
                  <div className="fs-15 fw-medium title-text">
                    Add Existing Asset
                  </div>
                  <div className="fs-13 fs-italic">
                    This option allows you to add an asset that already exists
                    in the system
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="col-md-6">
                <Nav.Link eventKey="second">
                  <div className="fs-15 fw-medium title-text">
                    Add New Asset
                  </div>
                  <div className="fs-13 fs-italic">
                    This options allows you to add an asset that does not exist
                    in the system
                  </div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="fs-16 fw-semibold">Asset</div>
                <div className="row row-gap mt-3">
                  <div className="col-md-6">
                    <label className="fw-medium pb-2">
                      Select asset category
                    </label>
                    <Dropdown
                      className="select__form"
                      onSelect={handleSelectTeam}
                    >
                      <Dropdown.Toggle
                        className={`select-title ${
                          selectCategory !== "Select asset category"
                            ? "selected"
                            : ""
                        }`}
                        style={{ height: "50px" }}
                      >
                        {selectCategory}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <form className="dropdown-search">
                          <button disabled>
                            <SearchIcon />
                          </button>
                          <input
                            onChange={(e) =>
                              setSelectTeamSearch(e.target.value)
                            }
                            value={selectTeamSearch}
                            type="text"
                            placeholder="Search"
                          />
                        </form>
                        <div className="dropdown-item-content">
                          {filteredACDData && filteredACDData.map((item, index) => (
                            <Dropdown.Item key={index} eventKey={item.assetCategoryName}>
                              {item.assetCategoryName}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="col-md-6">
                    <label className="fw-medium pb-2">Select asset</label>
                    <div className="checklist-box select__form">
                      <button
                        className="checklist-btn"
                        onClick={() => setShowChecklistList(!showChecklistList)}
                      >
                        {selectedAssets.length === 0 ? (
                          <div>
                            <span style={{ color: "#C5C7CD" }}>
                              Select asset from list
                            </span>
                            {selectedAssets.length > 0 && (
                              <span style={{ color: "#000" }}>
                                {selectedAssets.join(", ")}
                              </span>
                            )}
                          </div>
                        ) : (
                          <span style={{ color: "#000" }}>
                            {selectedAssets.join(", ")}
                          </span>
                        )}
                        <DownIcon />
                      </button>
                      <div
                        className={`checklist-list ${
                          showChecklistList ? "" : "hide d-none"
                        }`}
                      >
                        <form className="dropdown-search ps-0">
                          <button disabled>
                            <SearchIcon />
                          </button>
                          <input
                            onChange={(e) =>
                              setSelectLabourCostSearch(e.target.value)
                            }
                            type="text"
                            placeholder="Search"
                          />
                        </form>
                        <ul className="dropdown-item-content mt-2">
                          {filteredAssetData && filteredAssetData.map((item, index) => (
                            <li key={index}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id={item.assetName}
                                  checked={selectedAssets.includes(item.assetName)}
                                  onChange={() =>
                                    handleCheckboxChange(item.assetName)
                                  }
                                />
                                <label
                                  className="form-check-label" style={{fontSize: "14px"}}
                                  htmlFor={item.assetName}
                                >
                                  {item.assetName}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                <div className="fs-16 mt-3 fw-semibold">Asset</div>
                <div className="row row-gap mt-3">
                  <div className="col-md-6">
                    <label className="fw-medium pb-2">Name</label>
                    <input
                      className="modal-input-box"
                      type="text"
                      value={assetName}
                      onChange={(e) => setAssetName(e.target.value)}
                      style={{
                        background: "#F1EFEF",
                        width: "100%",
                        border: "0",
                        height: "50px",
                        borderRadius: "5px",
                        padding: "0 15px",
                      }}
                      placeholder="Enter asset name"
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="fw-medium pb-2">Serial Number</label>
                    <input
                      className="modal-input-box"
                      type="text"
                      value={serialNumber}
                      onChange={(e) => setSerialNumber(e.target.value)}
                      style={{
                        background: "#F1EFEF",
                        width: "100%",
                        border: "0",
                        height: "50px",
                        borderRadius: "5px",
                        padding: "0 15px",
                      }}
                      placeholder="Enter asset serial number"
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="fw-medium pb-2">Asset Category</label>
                    <Dropdown
                      className="select__form"
                      onSelect={handleSelectCategory}
                    >
                      <Dropdown.Toggle
                        className={`select-title ${
                          selectCategory !== "Select asset category"
                            ? "selected"
                            : ""
                        }`}
                        style={{ height: "50px" }}
                      >
                        {selectCategory}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <form className="dropdown-search">
                          <button disabled>
                            <SearchIcon />
                          </button>
                          <input
                            onChange={(e) =>
                              setACSearch(e.target.value)
                            }
                            value={ACSearch}
                            type="text"
                            placeholder="Search"
                          />
                        </form>
                        <div className="dropdown-item-content">
                          {filteredACDData && filteredACDData.map((item, index) => (
                            <Dropdown.Item key={index} eventKey={item.assetCategoryName}>
                              {item.assetCategoryName}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="col-md-6">
                    <label className="fw-medium pb-2">Asset Make</label>
                    <Dropdown
                      className="select__form"
                      onSelect={handleSelectMake}
                    >
                      <Dropdown.Toggle
                        className={`select-title ${
                          selectMake !== "Select asset make" ? "selected" : ""
                        }`}
                        style={{ height: "50px" }}
                      >
                        {selectMake}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <form className="dropdown-search">
                          <button disabled>
                            <SearchIcon />
                          </button>
                          <input
                            onChange={(e) =>
                              setAMSearch(e.target.value)
                            }
                            type="text"
                            placeholder="Search"
                            value={AMSearch}
                          />
                        </form>
                        <div className="dropdown-item-content">
                          {filteredAMData && filteredAMData.map((item, index) => (
                            <Dropdown.Item key={index} eventKey={item.makeName}>
                              {item.makeName}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col-md-6">
                    <label className="fw-medium pb-2">Asset Model</label>
                    <input
                      className="modal-input-box"
                      type="text"
                      value={assetModel}
                       nonChange={(e) => setAssetModel(e.target.value)}
                      style={{
                        background: "#F1EFEF",
                        width: "100%",
                        border: "0",
                        height: "50px",
                        borderRadius: "5px",
                        padding: "0 15px",
                      }}
                      placeholder="Enter asset  model"
                    />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <button  className="delate-btn" onClick={(e)=> handleSubmit(e)}>
              Add
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddAssetModal;