import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import { set } from "lodash";
import {
  ADD_PM_CHECKLIST,
  ADD_PM_PROJECTED_PART,
  GET_ALL_PARTS,
  GET_LOCATION_BY_PART_ID,
} from "../../../utls/constants";
import { get, post } from "../../../Services/ApiHelper";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { TemplateDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { toast } from "react-toastify";
import Astericks from "../../Common/Asterick";

const AddPartsModal = (props) => {
  const { template, setTemplate } = React.useContext(TemplateDetailsContext);

  const { id } = useParams();
  const [selectValue, setSelectValue] = useState({
    part: "Select",
    partId: 0,
    location: "Select",
    locationId: 0,
  });

  const [projectedParts, setProjectedParts] = useState([]);
  const [partLocationData, setPartLocationData] = useState([]);
  const [quantity, setQuantity] = useState(0);
  let selectedInfoArray = [];

  let [submit, setSubmit] = useState(false);

  const bearerToken = localStorage.getItem("bearerToken");

  const [selectedPart, setselectedPart] = useState("Select");
  const [selectedPartLocation, setselectedPartLocation] = useState(
    "Select location of part"
  );

  useEffect(() => {
    let parts = [];
    const ScheduleTemplateDetails = JSON.parse(
      localStorage.getItem("selectedProjectedPartsNew")
    );
    parts = ScheduleTemplateDetails;
    get(GET_ALL_PARTS, bearerToken)
      .then((response) => {
        // remove parts that have already been added
        if (parts) {
          response = response.filter((part) => {
            return !parts.some((item) => item.part === part.partName);
          });
        } else {
          response = response;
        }

        setProjectedParts(response);
      })
      .catch((error) => console.log("An error occured"));
    setQuantity(0);
    setselectedPart("Select");
  }, [props.show]);

  useEffect(() => {
    const partTableData = JSON.parse(
      localStorage.getItem("selectedProjectedParts")
    );

    if (partTableData) {
      setQuantity(partTableData?.quantity);
    }
  }, []);

  const [partLocationSearch, setPartLocationSearch] = useState("");
  //part category search input
  const [partSearch, setPartSearch] = useState("");

  //filter part and location data
  const filteredPartData = projectedParts.filter((item) => {
    return item.partName.toLowerCase().includes(partSearch.toLowerCase());
  });
  const filteredPartLocationData = partLocationData.filter((item) => {
    return item.locationName
      .toLowerCase()
      .includes(partLocationSearch.toLowerCase());
  });

  const updateSelectedInfoInLocalStorage = () => {
    const selectedInfo = {
      part: selectValue?.part,
      partId: selectValue?.partId,
      quantity: parseInt(quantity, 10),
    };

    localStorage.setItem("scheduleTemplateParts", JSON.stringify(selectedInfo));
  };

  const handlePartSelect = (eventKey) => {
    const partsObj = projectedParts.find((p) => p.partName === eventKey);

    if (partsObj) {
      setSubmit(true);
      setselectedPart(eventKey);
      setSelectValue(
        { ...selectValue, part: eventKey, partId: partsObj.id },
        () => {}
      );
      updateSelectedInfoInLocalStorage();
      setPartSearch("");
    }

    updateSelectedInfoInLocalStorage();
  };

  useEffect(() => {
    if (submit) {
      updateSelectedInfoInLocalStorage();
    }
  }, [selectValue.part, selectValue.location, quantity]);
  const [requiredPart, setRequiredPart] = useState(null);
  const [requiredQuantity, setRequiredQuantity] = useState(null);
  const handleAddButtonClick = () => {
    const pervArray = JSON.parse(
      localStorage.getItem("selectedProjectedPartsNew")
    );
    let hasError = false;

    // Check if all required fields are filled
    if (selectedPart === "Select") {
      setRequiredPart("Required *");
      hasError = true;
    } else {
      setRequiredPart(null);
    }

    if (quantity === 0 || quantity === "") {
      setRequiredQuantity("Required *");
      hasError = true;
    } else {
      setRequiredQuantity(null);
    }

    if (!hasError) {
      const selectedInfo = {
        part: selectedPart,
        partId: selectValue?.partId,
        quantity: parseInt(quantity, 10),
      };
      const scheduleTemplateParts = {
        pmTemplateId: id,
        partId: selectValue?.partId,
        quantityProjected: parseInt(quantity, 10),
      };

      if (props.fromScheduleTemplate != null) {
        post(ADD_PM_PROJECTED_PART, scheduleTemplateParts, bearerToken).then(
          (response) => {
            const contextSchedule = {
              id: response.data,
              partName: selectedPart,
              partId: selectValue?.partId,
              quantityProjected: parseInt(quantity, 10),
            };
          
            if (response.succeeded) {
              setTemplate({
                ...template,
                projectedParts: [...template.projectedParts, contextSchedule],
              });
              toast.success(response.messages[0], {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: false,
              });
              // setTimeout(() => {
              //   window.location.reload();
              // }, 3000);
            } else {
              toast.error(response.messages[0], {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          }
        );
      }

      if (pervArray) {
        pervArray.map((item) => {
          selectedInfoArray.push(item);
        });
      }

      selectedInfoArray.push(selectedInfo);
      localStorage.setItem(
        "selectedProjectedPartsNew",
        JSON.stringify(selectedInfoArray)
      );

      setQuantity("");
      setselectedPart("Select");
      props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Projected Parts</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              Select parts and quantities required.
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Select Part
                  <Astericks />
                </label>
                <p style={{ color: "red" }}>{requiredPart} </p>
              </div>
              <Dropdown className="select__form" onSelect={handlePartSelect}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedPart !== "Select" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectedPart}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={partSearch}
                      onChange={(e) => setPartSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredPartData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.partName}>
                        {item.partName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Quantity
                  <Astericks />
                </label>
                <p style={{ color: "red" }}>{requiredQuantity} </p>
              </div>

              <input
                className="modal-input-box"
                type="number"
                value={quantity}
                min={0}
                onChange={(e) => {
                  // const inputValue = parseInt(e.target.value);
                  setQuantity(e.target.value);

                  updateSelectedInfoInLocalStorage();
                }}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter quantity required"
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            <Link
              to=""
              className="delate-btn"
              onClick={(e) => {
                e.preventDefault();
                handleAddButtonClick();
              }}
            >
              Add
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddPartsModal;
