/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import EditIcon2 from "../../Assets/Icons/EditIcon2";
import DeleteIcon from "../../Assets/Icons/DeleteIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import DeleteModal from "../WorkOrders/Modal/DeleteModal";
import EditAssetCategoryModal from "../WorkOrders/Modal/EditAssetCategoryModal";
import usefetchData from "../../hooks/useFetchData";
import { HiDotsVertical } from "react-icons/hi";
// import useMutateData from "../../hooks/useMutateData";
import { toast } from "react-toastify";
import useMutateData from "../../hooks/useMutateData";

const AssetCategories = () => {
  const [editModlaShow, setEditModlaShow] = useState(false);
  const [RequestShow, setRequestShow] = useState(false); //for modal
  const [searchText, setSearchText] = useState(""); //for search
  const [category, setCategory] = useState({});

  const { data: assets, isLoading: isLoadingAssets } = usefetchData(
    ["asset-category-two", searchText],
    `/Assets/GetAllAssetMakes`,
    {
      
    },
    "Failed to fetch asset category data",
    true
  );

  const { data, isLoading } = usefetchData(
    ["asset-category", searchText],
    `/Assets/GetAssetCategoriesAssetCount`,
    {
      params: {
        search: searchText,
      },
    },
    "Failed to fetch asset category data",
    true
  );

    // dete asset category mutation
    const {mutate, isLoading: isDeleting} = useMutateData({
      url: `/Assets/DeleteAssetCategory`,
      method: "POST",
      onSuccessfullMutation: (data) => {
        if (data?.data?.succeeded === true) {
          toast.success(data?.data?.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setRequestShow(false);
        } else {
          toast.error(data?.data?.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      },
      successMessage: `Successfully recalled costing`,
      errorMessage: "Work order creation failed",
      queryKeysToInvalidate: [["asset-category", searchText]],
    })
  
    const deleteAssetCategory = () => {
      const requestBody = {
        id: category.id
      }
      mutate(requestBody)
    }

  return (
    <>
      <div className="d-flex gap-3 align-items-center justify-content-between my-4">
        <form
          action=""
          className="search__box"
          style={{ maxWidth: "150px", marginLeft: "auto" }}
        >
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Search"
          />
        </form>
      </div>
      <div className="grouping-content asset-categories">
        {data?.map((item) => (
          <div
            className="groping-item "
            key={item.id}
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
          >
            <div className="groping-title d-flex align-items-center justify-content-between">
              <div className="fs-18 fw-bold" style={{ color: "#091E42" }}>
                {item?.assetCategoryName}
              </div>
              <div className="d-flex align-items-center">
                <div className="fs-14" style={{ color: "#1B2128" }}>
                  {item?.assetCount} Assets
                </div>
                <div className="dropdown select-dropdown">
                  <button
                    className="select-title border-0 pe-0 d-flex align-content-end justify-content-end"
                    data-bs-toggle="dropdown"
                  >
                    {/* <span className="fs-15 d-flex align-items-center gap-2 text-black" style={{ color: "#091E42" }}> */}
                    <HiDotsVertical />
                    {/* </span> */}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end select-menu">
                    <li
                      onClick={() => {
                        setCategory(item);
                        setEditModlaShow(true);
                      }}
                    >
                      <EditIcon2 /> Edit
                    </li>
                    <li
                      onClick={() => {
                        setCategory(item);
                        setRequestShow(true);
                      }}
                    >
                      <DeleteIcon /> Delete
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="fs-14 fw-medium lh-sm pt-3"
              style={{ color: "#667085" }}
            >
              {item?.assetCategoryDescription}
            </div>
          </div>
        ))}
      </div>
      <EditAssetCategoryModal
        show={editModlaShow}
        onHide={() => setEditModlaShow(false)}
        data={category}
        searchText={searchText}
      />
      <DeleteModal show={RequestShow} onHide={() => setRequestShow(false)} searchText={searchText} onDelete={deleteAssetCategory} isDeleting={isDeleting} />
    </>
  );
};

export default AssetCategories;
