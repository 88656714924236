import React, { useState, useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useFetchData from "../../hooks/useFetchData";
import { debounce } from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { Nav, Tab } from "react-bootstrap";
import PMPerformanceGrid from "./PMPerformanceReport";
import PMPerformanceGraph from "./PMPerformanceGraph";
import "./Reports.scss";
import { Link } from "react-router-dom";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import qs from 'qs';

// function to flattten the filter object
const flattenObject = (obj, parent, res = {}) => {
  for (let key in obj) {
    let propName = parent ? parent + "." + key : key;
    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

const Reports = () => {
  const [endDate, setEndDate] = useState(null);
  const [calendarGrouping, setCalendarGrouping] = useState("weekly");
  const [categoryOfWorkIds, setCategoryOfWorkIds] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerPage, setRequestsPerPage] = useState(10);
  const [filters, setFilters] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [activeTab, setActiveTab] = useState("grid");

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const handleRun = () => {
    if (!startDate || !endDate) {
      setValidationMessage("Start Date and End Date are required.");
      return;
    }
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 365) {
      setValidationMessage("The date range should not be longer than a year.");
      return;
    }

    setValidationMessage("");
    handleRunClick();
  };

  const handleRunClick = () => {
    const filterObject = {
      // pageNumber: currentPage,
      // pageSize: requestsPerPage,
      filter: {
        StartDate: startDate
          ? new Date(
              startDate.getTime() - startDate.getTimezoneOffset() * 60000
            ).toISOString()
          : null,
        EndDate: endDate
          ? new Date(
              endDate.getTime() - endDate.getTimezoneOffset() * 60000
            ).toISOString()
          : null,
        CalendarGrouping: calendarGrouping,
        CategoryOfWorkIds: categoryOfWorkIds.map((category) => category.id),
      },
    };

    setFilters(flattenObject(filterObject));
  };


  const { data: workOrder, isLoading } = useFetchData(
    ["report-data", filters],
    `/Report/GetPMPerformance`,
    {
      params: {
        ...filters,
      },
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
    },
    "Error fetching work orders",
    !!filters,
    true
  );

  const { data: categoryOfWork, isLoading: isLoadingCow } = useFetchData(
    ["work-category"],
    `/CategoryOfWorks/GetAllWorkCategories`,
    {},
    "Error fetching category of work"
  );

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };

  const getCategoryNames = () => {
    return categoryOfWorkIds.map(
      (id) =>
        categoryOfWork.find((category) => category.id === id)
          ?.categoryOfWorkName ?? ""
    );
  };

  const formattedFilters = useMemo(() => {
    if (!filters) return null;

    return {
      startDate: formatDate(filters["filter.StartDate"]),
      endDate: formatDate(filters["filter.EndDate"]),
      calendarGrouping: filters["filter.CalendarGrouping"],
      categoryOfWork: getCategoryNames(
        filters["filter.CategoryOfWorkIds"]
      ).join(", "),
    };
  }, [filters]);

  const handleExcelExport = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        ["PM Performance Report"],
        [
          `Start Date: ${formattedFilters?.startDate ?? ""}`,
          `Category of Work: ${formattedFilters?.categoryOfWork ?? "All"}`,
        ],
        [
          `End Date: ${formattedFilters?.endDate ?? ""}`,
          `Calendar Grouping: ${formattedFilters?.calendarGrouping ?? "Weekly"}`,
        ],
        [],
      ],
      { origin: "A1" }
    );
  
    let jsonData = [];
  
    if (activeTab === "grid") {
      jsonData = workOrder?.data || [];
    } else if (activeTab === "graph") {
      // Placeholder: Modify this to export the graph data if applicable
      jsonData = [["Graph Data Placeholder"]];
    }
  
    // Extract specific fields and format createdDate
    const formattedData = jsonData.map((item) => ({
      "Calendar Period": item.calendarPeriod,
      "Scheduled": item.scheduled,
      "Completed": item.completed,
      "On Hold": item.onHold,
      "MTFR": item.mtfr
    }));
    
  
    // Add headers manually
    const headers = [
      "Calendar Period",
      "Scheduled",
      "Completed",
      "On Hold",
      "MTFR"
    ];
    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A5" });
  
    // Add data below the headers
    XLSX.utils.sheet_add_json(ws, formattedData, {
      origin: "A6",
      skipHeader: true, // Skip default header
    });
  
    // Calculate maximum column widths
    const calculateMaxWidths = (data) => {
      const maxLengths = [];
  
      data.forEach((row) => {
        Object.values(row).forEach((cell, colIndex) => {
          const cellValue = String(cell || "");
          const length = cellValue.length;
  
          if (!maxLengths[colIndex] || length > maxLengths[colIndex]) {
            maxLengths[colIndex] = length;
          }
        });
      });
  
      return maxLengths.map((length) => ({ wch: length + 2 })); // Add padding
    };
  
    // Get maximum widths for both the header and the content
    const headerWidths = calculateMaxWidths([headers]);
    const contentWidths = calculateMaxWidths(formattedData.map(Object.values));
  
    const maxColumnWidths = headerWidths.map((width, index) => ({
      wch: Math.max(width.wch, contentWidths[index]?.wch || 0),
    }));
  
    // Set column widths and enable text wrapping
    ws["!cols"] = maxColumnWidths;
    ws["!rows"] = [{ hpx: 20 }]; // Example: set height for header row
  
    // Add color to the header
    const headerRange = XLSX.utils.decode_range("A5:H5"); // Adjust range as needed
  
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 4, c: C }); // Row 5 is index 4
      if (!ws[cellAddress]) continue;
  
      ws[cellAddress].s = {
        fill: {
          fgColor: { rgb: "d57d2a" },
        },
        font: {
          bold: true,
          color: { rgb: "FFFFFF" },
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
      };
    }
  
    XLSX.utils.book_append_sheet(wb, ws, "PM Performance Report");
    XLSX.writeFile(wb, "PM_Performance.xlsx");
  };

  const handlePdf = useReactToPrint({
    content: () =>
      document.getElementById(
        activeTab === "grid" ? "pm-performance" : "pm-performance-graph"
      ),
      pageStyle: `
      @page {
        size: A4 landscape;
        margin: 10mm;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
        }
        #pm-performance-graph {
          width: 100%;
        }
        .e-chart {
          width: 100% !important;
        }
      }
    `,
  });

  const workOrderData = workOrder?.data;

  const maxScheduled =
    workOrderData && Math.max(...workOrderData?.map((data) => data.scheduled));

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleCalendarGroupingChange = (event) => {
    setCalendarGrouping(event.target.value);
  };

  const handleCategoryChange = (selectedCategory) => {
    setCategoryOfWorkIds(prevCategories => {
      if (prevCategories.includes(selectedCategory)) {
        return prevCategories.filter(id => id !== selectedCategory);
      } else {
        return [...prevCategories, selectedCategory];
      }
    });
  };

  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="work-header">
              <div className="fs-20">Reports - PM Performance</div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="">PM Reports</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inspection-cate">
              <div className="request-box-body p-0">
                <Tab.Container
                  defaultActiveKey="grid"
                  onSelect={(k) => setActiveTab(k)}
                >
                  <Nav className="workCostings-nav-report">
                    <Nav.Item>
                      <Nav.Link eventKey="grid">
                        PM Performance Report
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="graph">
                        PM Performance Graph
                      </Nav.Link>
                    </Nav.Item>
                    <div
                      className="selection-grope"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <div>
                        <p>Report Filters: </p>
                      </div>
                      <Dropdown className="select__form">
                        <Dropdown.Toggle className="select-title bg-white">
                          {categoryOfWorkIds?.length < 1 && (
                            <span className="fs-13">
                              Category of Work &nbsp;
                            </span>
                          )}

                          <span className="fs-14 d-flex align-items-center gap-1">
                            {categoryOfWorkIds?.length === 0
                              ? "All"
                              : categoryOfWorkIds
                              .slice(0, 2)
                                  .map(
                                    (id) =>
                                      categoryOfWork.find(
                                        (item) => item.id === id
                                      )?.categoryOfWorkName
                                  )
                                  .join(", ")}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <form className="dropdown-search bg-white">
                            <button disabled>
                              <SearchIcon />
                            </button>
                            <input
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              type="text"
                              placeholder="Search"
                            />
                          </form>
                          <div className="dropdown-item-content">
                            {categoryOfWork?.map((item) => (
                              <Dropdown.Item
                                key={item.id}
                                className="checklists-list"
                                onClick={() => handleCategoryChange(item.id)}
                              >
                                <input
                                  type="checkbox"
                                  checked={categoryOfWorkIds.includes(item.id)}
                                  onChange={() => {}} // Add this line
                                  readOnly={false} // Change this line
                                />
                                <span className="checkbox-label">
                                  {item?.categoryOfWorkName}
                                </span>
                              </Dropdown.Item>
                            ))}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>

                      <Dropdown className="select__form">
                        <Dropdown.Toggle className="select-title bg-white">
                          <span className="fs-13">Calendar Grouping</span>
                          <span className="fs-14 d-flex align-items-center gap-1">
                            {calendarGrouping.charAt(0).toUpperCase() +
                              calendarGrouping.slice(1)}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              handleCalendarGroupingChange({
                                target: { value: "weekly" },
                              })
                            }
                          >
                            Weekly
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              handleCalendarGroupingChange({
                                target: { value: "monthly" },
                              })
                            }
                          >
                            Monthly
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <div className="select-dropdown">
                        <div className="select-title">
                          <div className="fs-13">Date From:</div>
                          <div className="format-date">
                            <DatePicker
                              maxDate={endDate}
                              selected={startDate}
                              onChange={(date) => handleStartDateChange(date)}
                              customInput={<ExampleCustomInput />}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="select-dropdown">
                        <div className="select-title">
                          <div className="fs-13">Date To:</div>
                          <div className="format-date">
                            <DatePicker
                              minDate={startDate}
                              selected={endDate}
                              onChange={(date) => handleEndDateChange(date)}
                              customInput={<ExampleCustomInput />}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        className="add-button my-2"
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          background: "rgba(213, 125, 42, 0.87)",
                          padding: "8px 24px 8px 24px",
                          color: "white",
                          borderRadius: "8px",
                        }}
                        onClick={handleRun}
                      >
                        Run
                      </button>

                      {validationMessage && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                          {validationMessage}
                        </div>
                      )}
                    </div>
                    <div
                      className="flex icon-thin"
                      style={{
                        color: "gray",
                        fontWeight: 100,
                        fontSize: "15px",
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                        paddingTop: "15px",
                      }}
                    >
                      <FirstPageIcon
                        className="icon-thin"
                        style={{
                          color: workOrder?.hasPreviousPage
                            ? "gray"
                            : "lightgray",
                          cursor: workOrder?.hasPreviousPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasPreviousPage) {
                            setCurrentPage(1);
                            setFilters((prev) => ({ ...prev, pageNumber: 1 }));
                          }
                        }}
                      />
                      <button
                        style={{
                          color: workOrder?.hasPreviousPage
                            ? "gray"
                            : "lightgray",
                          cursor: workOrder?.hasPreviousPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasPreviousPage) {
                            setCurrentPage((prev) => {
                              const newPage = prev - 1;
                              setFilters((prevFilters) => ({
                                ...prevFilters,
                                pageNumber: newPage,
                              }));
                              return newPage;
                            });
                          }
                        }}
                        disabled={!workOrder?.hasPreviousPage}
                      >
                        <ArrowLeft className="icon-thin" />
                      </button>
                      <button
                        style={{
                          color: workOrder?.hasNextPage ? "gray" : "lightgray",
                          cursor: workOrder?.hasNextPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasNextPage) {
                            setCurrentPage((prev) => {
                              const newPage = prev + 1;
                              setFilters((prevFilters) => ({
                                ...prevFilters,
                                pageNumber: newPage,
                              }));
                              return newPage;
                            });
                          }
                        }}
                        disabled={!workOrder?.hasNextPage}
                      >
                        <ArrowRight className="icon-thin" />
                      </button>

                      <LastPageIcon
                        className="icon-thin"
                        style={{
                          color: workOrder?.hasNextPage ? "gray" : "lightgray",
                          cursor: workOrder?.hasNextPage
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => {
                          if (workOrder?.hasNextPage) {
                            setCurrentPage(workOrder.totalPages);
                            setFilters((prev) => ({
                              ...prev,
                              pageNumber: workOrder.totalPages,
                            }));
                          }
                        }}
                      />
                      <form action="" style={{ width: "30px", height: "30px" }}>
                        <input
                          type="text"
                          placeholder="1"
                          value={workOrder?.currentPage}
                          readOnly
                          style={{
                            width: "100%",
                            height: "100%",
                            textAlign: "center",
                            padding: 0,
                          }}
                        />
                      </form>
                      <p style={{ margin: 0 }}>
                        of {workOrder?.totalPages || "1"}
                      </p>
                      <div
                        style={{
                          borderLeft: "1px solid gray",
                          height: "24px",
                          margin: "0 10px",
                        }}
                      ></div>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="icon-thin"
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            color: "gray",
                          }}
                        >
                          <SaveAltIcon className="icon-thin" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={handlePdf}>PDF</Dropdown.Item>
                          <Dropdown.Item onClick={handleExcelExport}>
                            Excel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Nav>
                  <hr />
                  <Tab.Content>
                    <Tab.Pane eventKey="grid">
                      <PMPerformanceGrid
                        workOrder={workOrder}
                        categories={categoryOfWork}
                        filters={filters}
                        categoryOfWorkIds={categoryOfWorkIds}
                        isLoading={isLoading}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="graph">
                      <PMPerformanceGraph
                        workOrder={workOrder}
                        maxScheduled={maxScheduled}
                        categories={categoryOfWork}
                        filters={filters}
                        categoryOfWorkIds={categoryOfWorkIds}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
