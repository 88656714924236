import React, { useContext, useEffect, useState } from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import AddPartsModal from "../modals/AddPartsModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import EditIcon2 from "../../../Assets/Icons/EditIcon2";
import DelateIcon2 from "../../../Assets/Icons/DelateIcon2";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import DeleteModal from "../modals/DeleteModal";
import EditTemplateDetailsModal from "../../WorkOrders/Modal/EditTemplateDetailsModal";
import { set, template } from "lodash";
import { TemplateDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import {
  ADD_PM_CHECKLIST,
  DELETE_PM_CHECKLIST,
  EDIT_PM_PROJECTED_PART,
  EDIT_PM_TEMPLATE,
  GET_ALL_PARTS,
  GET_CHECKLISTS,
  GET_PM_TEMPLATES,
  GET_PM_TEMPLATE_BY_ID,
} from "../../../utls/constants";
import { get, post } from "../../../Services/ApiHelper";
import { toast } from "react-toastify";

const ScheduleTemplateDetails = () => {
  const { template, setTemplate } = useContext(TemplateDetailsContext);
  const tempChecklist = template?.checklists?.map((item) => {
    return {
      id: item.id,
      name: item.checkListName,
    };
  });

  const [addPartShow, setAddPartShow] = useState(false);
  const [selectedPart, setselectedPart] = useState("Select");
  const [selectedPartId, setSelectedPartId] = useState(0);
  const [selectedPartQuantity, setSelectedPartQuantity] = useState(0);
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [modalEditDetailsShow, setModalEditDetailsShow] = useState(false);
  const [projectedParts, setProjectedParts] = useState([]);
  const [selectedChecklistId, setSelectedChecklistId] = useState([]);

  const [partSearch, setPartSearch] = useState("");
  const [projectParts, setProjectParts] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [submit, setSubmit] = useState(false);
  //checklist search
  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [selectedAssets, setSelectedAssets] = useState([]);

  const [checklistSearch, setChecklistSearch] = useState("");
  const [selectValue, setSelectValue] = useState({
    Id: 0,
    partName: "Select",
    partProjectedQuantity: 0,
  });
  const { id } = useParams();
  const [checkListData, setCheckList] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState([]);
  //filter checklist by search
  const filteredChecklistData = checkListData.filter((item) =>
    item.name.toLowerCase().includes(checklistSearch.toLowerCase())
  );

  const handlePartSelect = (eventKey) => {
    const part = projectedParts.find((item) => item.partName === eventKey);
    setselectedPart(eventKey);
    setSelectValue({
      ...selectValue,
      part: eventKey,
      partId: part.id,
    });
  };

  //filter project parts data
  const filteredPartData = projectedParts.filter((item) => {
    return item.partName.toLowerCase().includes(partSearch.toLowerCase());
  });

  useEffect(() => {
    get(GET_CHECKLISTS, localStorage.getItem("bearerToken"))
      .then((response) => {
        setCheckList(response["data"]);
      })
      .catch((error) => console.log("An error occured"));
    get(GET_ALL_PARTS, localStorage.getItem("bearerToken"))
      .then((response) => {
        setProjectedParts(response);
      })
      .catch((error) => console.log("An error occured"));
  }, [id]);
  const handleCheckboxChange = (asset) => {
    const id = checkListData.find((item) => item.name === asset).id;
    setSelectedAssets((prevSelectedAssets) => {
      if (prevSelectedAssets?.includes(asset)) {
        return prevSelectedAssets?.filter(
          (selectedAsset) => selectedAsset !== asset
        );
      } else {
        return [...prevSelectedAssets, asset];
      }
    });
    setSelectedChecklistId((prevSelectedChecklistId) => {
      if (prevSelectedChecklistId?.includes(id)) {
        return prevSelectedChecklistId?.filter(
          (selectedChecklistId) => selectedChecklistId !== id
        );
      } else {
        return [...prevSelectedChecklistId, id];
      }
    });
  };
  useEffect(() => {
    addChecklist();
  }, [selectedAssets]);
  const [partData, setPartData] = useState(null);
  const handleUpdate = () => {
    const updatedPart = {
      id: selectedPartId,
      partName: selectedPart,
      quantityProjected: selectedPartQuantity,
    };
    const partToUpdate = {
      id: partData.id,
      partId: partData.partId,
      quantityProjected: selectedPartQuantity,
    };

    post(
      EDIT_PM_PROJECTED_PART,
      partToUpdate,
      localStorage.getItem("bearerToken")
    ).then((response) => {
      if (response.succeeded) {
        setTemplate({
          ...template,
          projectedParts: template.projectedParts.map((part) =>
            part.id === selectedPartId ? updatedPart : part
          ),
        });
        toast.success(response.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(response.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });

    handleClose();
  };
  const handleProjectedPartEdit = (part) => {
    setPartData(part);
    setselectedPart(part.partName);
    setSelectedPartId(part.id);
    setSelectedPartQuantity(part.quantityProjected);
    handleShow();
  };
  const handleChecklistDelete = (item) => {
    post(
      DELETE_PM_CHECKLIST,
      { id: parseInt(item.id) },
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        if (response.succeeded) {
          setTemplate({
            ...template,
            checklists: template.checklists.filter(
              (checklist) => checklist.id !== item.id
            ),
          });

          setSelectedChecklistId((prevSelectedChecklistId) =>
            prevSelectedChecklistId.filter(
              (selectedChecklistId) => selectedChecklistId !== id
            )
          );
          console.log("selectedChecklistId", selectedChecklistId);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const navigate = useNavigate();
  const save = () => {
    setIsLoading(true);
    const data = {
      id: template.details.id,
      templateName: template.details.templateName,
      templateDescription: template.details.description,
      categoryOfWorkId: template.details.categoryOfWorkId,
      assetCategoryId: template.details.assetCategoryId,
      ticketPriorityId: template.details.ticketPriorityId,
      recurring: template.details.recursEvery,
      calendarTypeId: template.details.calendarTypeId,
      estimatedHours: template.details.estimatedHours,
    };
    post(EDIT_PM_TEMPLATE, data, localStorage.getItem("bearerToken"))
      .then((response) => {
        if (response.succeeded === true) {
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/work-schedule-templates");
        } else {
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const addChecklist = () => {
    setIsLoading(true);
    // from checklist data, get the selected checklists with same name as template.checklists
    const selectedChecklists = checkListData?.filter((item) =>
      template?.checklists?.includes(item.name)
    );

    const data = {
      pmTemplateId: parseInt(id),
      checkListFormListId: selectedChecklistId,
    };
    if (selectedChecklistId.length > 0) {
      post(ADD_PM_CHECKLIST, data, localStorage.getItem("bearerToken"))
        .then((response) => {
          
          if (response.succeeded) {
            setTemplate({
              ...template,
              checklists: selectedAssets,
            });
            setSelectedChecklistId([]);
            setIsLoading(false);
            toast.success(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            setIsLoading(false);
            setSelectedChecklistId([]);
            toast.error(response.messages[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
    }
  };

  return (
    <>
      <div className="order-details-content PMWorkScheduling pb-lg-4">
        <div className="fs-16 fw-bold">Schedule Template Details</div>
        <br />
        <br />
        <div className="template-details d-flex align-items-center justify-content-between">
          <div className="fs-16 fw-semibold projected-title">
            Template Details
          </div>
          <div className="text-end">
            <button
              className="add-part"
              onClick={() => setModalEditDetailsShow(true)}
            >
              Edit Details
            </button>
          </div>
        </div>
        <EditTemplateDetailsModal
          show={modalEditDetailsShow}
          // template={template}
          onHide={() => setModalEditDetailsShow(false)}
        />
        <br />
        <div className="row" style={{ rowGap: "24px" }}>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Template Name
            </div>
            <div className="fs-14 fw-medium">
              {template?.details?.templateName}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Category of Work:
            </div>
            <div className="fs-14 fw-medium">
              {template?.details?.categoryOfWorkName}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Asset Category
            </div>
            <div className="fs-14 fw-medium">
              {template?.details?.assetCategoryName}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Work recurs every:
            </div>
            <div className="fs-14 fw-medium">
              {template?.details?.recursEvery > 1
                ? template?.details?.recursEvery +
                  " " +
                  template?.details?.calendarTypeName +
                  "s"
                : template?.details?.recursEvery +
                  template?.details?.calendarTypeName}
            </div>
          </div>
        </div>
        <br /> <br />
        <div className="row" style={{ rowGap: "24px" }}>
          <div className="col-md-6">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Description:
            </div>
            <div className="fs-14 fw-medium" style={{ lineHeight: "1.3" }}>
              {template?.details?.description}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Work Estimate Hours{" "}
            </div>
            <div className="fs-14 fw-medium">
              {template?.details?.estimatedHours}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className="fs-14 fw-semibold pb-2"
              style={{ color: "#00000061" }}
            >
              Priority:
            </div>
            <div className="fs-14 fw-bold" style={{ color: "#D57D2A" }}>
              {template?.details?.priorityName}
            </div>
          </div>
        </div>
        <hr />
        <div className="row projected-con">
          <div className="col-lg-12">
            <div className="fs-16 fw-semibold projected-title">
              Projected Parts
            </div>
            <div className="parts-box table-responsive-sm">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Parts</th>
                    <th scope="col">Quantity</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {template?.projectedParts?.map((part, index) => (
                    <tr key={index}>
                      <td>
                        <input readOnly type="text" value={part?.partName} />
                      </td>
                      <td>
                        <input
                          readOnly
                          type="text"
                          value={part?.quantityProjected}
                        />
                      </td>
                      <td>
                        <div className="button-con">
                          <button
                            onClick={() => {
                              handleProjectedPartEdit(part);
                            }}
                          >
                            <EditIcon2 />
                          </button>
                          <Modal
                            show={show}
                            onHide={handleClose}
                            centered
                            className="medium-modal"
                          >
                            <div
                              className="delete-content"
                              style={{ padding: "28px 30px" }}
                            >
                              <div className="d-flex align-items-center gap-4">
                                <span className="fs-16 fw-bold">
                                  Edit Projected Parts
                                </span>
                                <span
                                  className="fs-14"
                                  style={{ color: "#72777A" }}
                                >
                                  Select parts and quantities required.
                                </span>
                              </div>

                              <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
                                <div className="col-md-12">
                                  <label className="fw-medium pb-2">
                                    Select Part
                                  </label>
                                  <Dropdown
                                    className="select__form"
                                    onSelect={handlePartSelect}
                                  >
                                    <Dropdown.Toggle
                                      className={`select-title ${
                                        selectedPart !== "Select Part"
                                          ? "selected"
                                          : ""
                                      }`}
                                      style={{ height: "50px" }}
                                      disabled={true}
                                    >
                                      {selectedPart}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <form className="dropdown-search">
                                        <button disabled>
                                          <SearchIcon />
                                        </button>
                                        <input
                                          value={partSearch}
                                          onChange={(e) =>
                                            setPartSearch(e.target.value)
                                          }
                                          type="text"
                                          placeholder="Search"
                                        />
                                      </form>
                                      <div className="dropdown-item-content">
                                        {filteredPartData.map((item, index) => (
                                          <Dropdown.Item
                                            key={item.id}
                                            eventKey={item.partName}
                                          >
                                            {item.partName}
                                          </Dropdown.Item>
                                        ))}
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                <div className="col-md-12">
                                  <label className="fw-medium pb-2">
                                    Quantity
                                  </label>
                                  <input
                                    type="number"
                                    className="modal-input-box"
                                    style={{
                                      background: "#F1EFEF",
                                      width: "100%",
                                      border: "0",
                                      height: "50px",
                                      borderRadius: "5px",
                                      padding: "0 15px",
                                    }}
                                    min={0}
                                    value={selectedPartQuantity}
                                    onChange={(e) =>
                                      setSelectedPartQuantity(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="button-group"
                                style={{
                                  marginTop: "25px",
                                  display: "flex",
                                  justifyContent: "end",
                                  gap: "30px",
                                }}
                              >
                                <button
                                  className="cancel-btn"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </button>
                                <button
                                  to=""
                                  className="delate-btn"
                                  onClick={(e) => handleUpdate()}
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </Modal>
                          <button
                            // onClick={() =>
                            //   setProjectParts(
                            //     projectParts.filter(
                            //       (item) => item.id !== part.id
                            //     )
                            //   )
                            // }
                            onClick={() => {
                              setSelectedPartId(part.id);
                              setDelateModalShow(true);
                            }}
                          >
                            <DelateIcon2 />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="text-end">
                <button
                  className="add-part"
                  onClick={() => setAddPartShow(true)}
                >
                  Add Part
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row checklists-con">
          <div className="col-lg-12">
            <div className="fs-16 fw-semibold">Checklists</div>
            <div className="dropdown Checklists-dropdown">
              <button className="btn checklists-btn" data-bs-toggle="dropdown">
                Select Checklist <DownIcon />
              </button>
              <ul class="dropdown-menu pb-2">
                <li>
                  <form className="dropdown-search ps-0">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setChecklistSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                </li>
                <ul className="dropdown-item-content my-2">
                  {filteredChecklistData?.map((item, index) => (
                    <li key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={item.name}
                          checked={selectedAssets.includes(item.name)}
                          onChange={() => handleCheckboxChange(item.name)}
                        />
                        <label className="form-check-label" htmlFor={item.name}>
                          {item.name}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </ul>
            </div>
            <div className="checklists-box">
              <div className="fs-15 checklists-title">Checklists</div>
              <ul className="checklists-list">
                {console.log("templhhhate", tempChecklist)}
                {tempChecklist?.map((item, index) => (
                  <li key={index}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="Nozzle"
                      />
                      <label className="form-check-label" for="Nozzle">
                        {item.name}
                      </label>
                    </div>
                    <button
                      onClick={() => {
                        handleChecklistDelete(item);
                      }}
                    >
                      <DelateIcon2 />
                    </button>
                  </li>
                ))}
              </ul>

              {/* {isLoading ? (
                <div className="text-end m-4">
                  <Spinner
                    animation="border"
                    style={{
                      color: "#D57D2A",
                      position: "absolute",
                      right: "10%",
                      top: "10%",
                    }}
                  />
                </div>
              ) : (
                <div className="text-end m-4">
                  <button
                    style={{
                      color: "#D57D2A",
                      cursor: "pointer",
                      borderBottom: "1px solid #D57D2A",
                    }}
                    className="add-part"
                    onClick={() => {
                      addChecklist();
                    }}
                  >
                    Add Checklist
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="details-buttons d-flex justify-content-between">
          <Link
            style={{ display: "none" }}
            to="/preventive-works/create-pm-work/assets"
            className="next-btn ms-0"
          >
            Previous
          </Link>
          {/* {isLoading && (
            <Spinner animation="border" style={{ color: "#D57D2A" }} />
          )}
          <button onClick={() => save()} className="next-btn ms-0">
            Save
          </button> */}
        </div>
      </div>
      <AddPartsModal
        show={addPartShow}
        onHide={() => setAddPartShow(false)}
        fromScheduleTemplate={"fromScheduleTemplate"}
      />
      <DeleteModal
        show={delateModalShow}
        id={selectedPartId}
        template={template}
        fromScheduleTemplate={"fromScheduleTemplate"}
        onHide={() => setDelateModalShow(false)}
      />
    </>
  );
};

export default ScheduleTemplateDetails;
