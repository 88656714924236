import React, { useEffect, useState } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import AddIcon from "../../Assets/Icons/AddIcon";
import { Link } from "react-router-dom";
import exportToExcel from "../../utls/exportToExcel";
import { GET_SUBMITTED_COSTS } from "../../utls/constants";
import { get } from "../../Services/ApiHelper";
import exportToExcelMain from "../../utls/exportToExecelMain";
import { id } from "date-fns/locale";
const WorkCostsHeader = () => {
  const [workOrderData, setWorkOrderData] = useState([]);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [RequestsPerPage, setRequestsPerPage] = useState(1000);
  const [allResponses, setAllResponses] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [me, setMe] = useState(false);
  useEffect(() => {
    get(
      GET_SUBMITTED_COSTS(debouncedSearch, RequestsPerPage, currentPage, me),
      localStorage.getItem("bearerToken")
    ).then((response) => {
      const data = response["data"]?.map((workCost) => {
        return {
          id: workCost.ticketId,
          ticketRef: workCost.ticketRef,
          ticketTitle: workCost.ticketTitle,
          locationName: workCost.locationName,
          approvalLevelStateName: workCost.approvalLevelStateName,
          costingTeamUserName: workCost.costingTeamUserName,
          costingTotal: workCost.costingTotal,
          dateSubmitted: workCost.dateSubmitted,
        };
      });
      setWorkOrderData(response["data"]);
      setIsLoading(false);
      setAllResponses(response);
    });
  }, [debouncedSearch, RequestsPerPage, currentPage, me]);
  return (
    <>
      <div className="work-header">
        <div className="fs-20">Work Costs</div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-15 d-flex align-items-center gap-2">
              Actions <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end select-menu">
            <li onClick={() => exportToExcelMain(workOrderData, "Work_Cost")}>
              <Link to="">
                <AddIcon />
                Export to Excel
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default WorkCostsHeader;
