import React, { useState } from "react";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import { Accordion } from "react-bootstrap";
import AddUsedPartsModal from "../Modals/AddUsedPartsModal";
import EditReturnedPartsModal from "../Modals/EditReturnedPartsModal";
import DeleteModal from "../Modals/DeleteModal";
import AssetsIcons from "../../../Assets/Icons/AssetsIcons";
import AddAssetModal from "../../WorkOrders/Modal/AddAssetModal";
import AssetTransferModal from "../../WorkOrders/Modal/AssetTransferModal";
import { useParams } from "react-router-dom";
import usefetchData from "../../../hooks/useFetchData";
import { toast } from "react-toastify";
import useMutateData from "../../../hooks/useMutateData";

const ViewLocationAssets = () => {
  const [addUsedPartsShow, setAddUsedPartsShow] = useState(false);
  const [addReturnedPartsShow, setAddReturnedPartsShow] = useState(false);
  const [editReturnedPartsShow, setEditReturnedPartsShow] = useState(false);
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [transferModalShow, setTransferModalShow] = useState(false);
  const [assetLocationId, setAssetLocationId] = useState(null);
  const [filters, setFilters] = useState({});
  const [assetId, setAssetId] = useState(null);

  // get id from params
  const { id } = useParams();

  // get asset data by id
  const { data: partsData, isLoading: isLoadingCondition } = usefetchData(
    ["location-summary", parseInt(id)],
    `/Locations/GetLocationSummaryById/${id}`,
    {
      
    },
    "Error fetching asset data by id"
  );

  // delete locationGroup mutation
  const { mutate, isLoading: isDeletingLocationAssset } = useMutateData({
    url: `/Locations/DeleteAssetFromLocation`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setDelateModalShow(false); // Hide delete modal
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully deleted the asset`,
    errorMessage: "Asset deletion failed",
    queryKeysToInvalidate: [["location-summary", parseInt(id)]],
  });

  const onDelete = async () => {
    const requestBody = {
      id: assetId,
    };
    mutate(requestBody);
  };

  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">LOCATION ASSETS</div>
        </div>

        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">ASSETS</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div
                  className="table-responsive-sm request_table"
                  style={{ maxWidth: "100%" }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Asset Name</th>
                        <th scope="col">Serial No.</th>
                        <th scope="col">Make & Model</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {partsData?.locationAssets?.length > 0 &&
                        partsData?.locationAssets?.map((item, i) => (
                          <tr key={i}>
                            <td>
                              <div style={{ color: "#000", fontSize: "15px" }}>
                                {item.assetName}
                              </div>
                              <div style={{ color: "#6B788E", fontSize: "15px" }}>
                                Category: {item.assetCategory}
                              </div>
                            </td>
                            <td>
                              <div style={{ color: "#000", fontSize: "15px" }}>
                                {item.serialNumber}
                              </div>
                              <div style={{ color: "#6B788E", fontSize: "15px" }}>
                                Tag: {item.serialTag}
                              </div>
                            </td>
                            <td>
                              <div style={{ color: "#000", fontSize: "15px" }}>
                                {item.makeName}
                              </div>
                              <div style={{ color: "#6B788E", fontSize: "15px" }}>
                                Model: {item.assetId}
                              </div>
                            </td>
                            <td>
                              <div className="button-con">
                                <button
                                  onClick={() => setTransferModalShow(true)}
                                >
                                  <AssetsIcons />
                                </button>
                                <button
                                  onClick={() => {
                                    setAssetId(item.assetId);
                                    setDelateModalShow(true);
                                    
                                  }}
                                >
                                  <DeleteIcon />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="text-end">
                  <button
                    className="add-button my-3"
                    onClick={() => setAddReturnedPartsShow(true)}
                  >
                    Add Asset to Location
                  </button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <AddUsedPartsModal
        show={addUsedPartsShow}
        onHide={() => setAddUsedPartsShow(false)}
      />
      <AddAssetModal
        show={addReturnedPartsShow}
        onHide={() => setAddReturnedPartsShow(false)}
      />
      <AssetTransferModal
        show={transferModalShow}
        onHide={() => setTransferModalShow(false)}
      />
      <DeleteModal
        isDeletingLocationAssset={isDeletingLocationAssset}
        onDelete={onDelete}
        show={delateModalShow}
        onHide={() => setDelateModalShow(false)}
      />
    </>
  );
};

export default ViewLocationAssets;
